@import "../components/banner";
@import "../common/newshome";
@import "../components/image_galery_slider";

.detail_page {
    max-width: 95%;
    margin: 80px auto 0;
    padding: 20px;

    h2 {
        margin-bottom: 15px;
    }

    .header {
        margin-bottom: 20px;

        button {
            border: none;
            outline: none;
            background-color: transparent;
            font-size: 14px;
            background-color: #f9f9f9;
            padding: 4px 10px;
            border-radius: 6px;
            transition: 0.3s all ease;

            &:hover {
                color: $theme-primary;
                transition: 0.3s all ease;
            }
        }

        a {
            color: $text-color;
            text-decoration: none;

            &:hover {
                color: $text-color;
            }
        }

        .d-flex {
            gap: 10px;

            .prp {
                border-left: 1px solid #e6e6e6;
                padding: 0 10px;
            }
        }
    }

    .sld_box {
        padding: 40px 0 60px;

        img {
            // border-radius: 15px;
            // width: 100%;
            // height: 600px;
            object-fit: cover;
        }

        .slick-next {
            right: 0;
            background-color: #f9f9f9;
            color: #000;
            border-radius: 4px 0 0 4px;
            padding: 10px 5px;
            height: 40px;
            width: 30px;
            transition: 0.3s all ease;

            &:before {
                content: "\f105";
                font-family: "Font Awesome 5 Pro";
                color: #000;
            }

            &:hover {
                &::before {
                    transition: 0.3s all ease;
                    color: $theme-primary;
                }
            }
        }

        .slick-prev {
            left: 0;
            background-color: #f9f9f9;
            border-radius: 0 4px 4px 0;
            padding: 10px 5px;
            z-index: 9;
            color: #000;
            height: 40px;
            width: 30px;
            transition: 0.3s all ease;

            &:before {
                content: "\f104";
                font-family: "Font Awesome 5 Pro";
                color: #000;
            }

            &:hover {
                &::before {
                    color: $theme-primary;
                    transition: 0.3s all ease;
                }
            }
        }
    }

    .det_box {
        .project_literature {
            h2 {
                margin-bottom: 10px;
                font-size: em(22);
                color: $theme-primary;
            }

            p {
                font-size: em(18);
                letter-spacing: 0.4px;
                color: $light-text;
            }

            .prdet {
                padding: 40px 0;

                h3 {
                    font-size: 20px;
                }

                .mndet {
                    gap: 15px 30px;
                    justify-content: space-between;

                    >div {
                        border-bottom: 1px solid #e6e6e6;
                        padding: 10px 0;
                        gap: 0 15px;
                        min-width: 280px;
                        max-width: 360px;
                        flex: 1;

                        i {
                            font-size: 22px;
                        }

                        .d-flex {
                            width: 100%;

                            span {
                                font-size: 15px;
                            }
                        }
                    }
                }
            }
        }

        .videocontainer {
            video {
                width: 100%;
                height: 100%;
                border-radius: 10px;
            }
        }

        .location {
            .map {
                width: 100%;
                border: 0;
                height: 400px;
            }
        }

        .ryt {
            .useful {
                padding: 0px;
                border: 1px solid #e6e6e6;
                background-color: #f9f9f9;
                border-radius: 6px;

                h3 {
                    padding: 10px;
                    text-align: center;
                    font-size: 20px;
                    border-bottom: 1px solid #e6e6e6;
                }

                >div {
                    padding: 20px;

                    a {
                        display: block;
                        color: $text-color;
                        text-decoration: none;
                        font-size: 14px;

                        &:not(:nth-last-of-type(1)) {
                            margin-bottom: 5px;
                        }

                        &:hover {
                            color: $theme-primary;
                        }
                    }
                }
            }
        }
    }

    .related {
        padding: 0 0 60px;

        .sld {
            .slick-slide {
                padding: 0 10px;
            }

            .slick-next {
                right: -20px;
                color: #000;
                border-radius: 4px 0 0 4px;
                padding: 10px 5px;
                height: 40px;
                width: 30px;
                transition: 0.3s all ease;

                &:before {
                    content: "\f105";
                    font-family: "Font Awesome 5 Pro";
                    color: #000;
                    font-size: 28px;
                }

                &:hover {
                    &::before {
                        transition: 0.3s all ease;
                        color: $theme-primary;
                    }
                }
            }

            .slick-prev {
                left: -20px;
                border-radius: 0 4px 4px 0;
                padding: 10px 5px;
                z-index: 9;
                color: #000;
                height: 40px;
                width: 30px;
                transition: 0.3s all ease;

                &:before {
                    content: "\f104";
                    font-family: "Font Awesome 5 Pro";
                    color: #000;
                    font-size: 28px;
                }

                &:hover {
                    &::before {
                        color: $theme-primary;
                        transition: 0.3s all ease;
                    }
                }
            }
        }
    }

    .contact {
        padding: 20px;
        border: 1px solid #e6e6e6;
        background-color: #f9f9f9;
        border-radius: 10px;

        .d-flex {
            gap: 10px 15px;

            a {
                color: #fff;
                text-decoration: none;
                font-size: 16px;
                display: flex;
                align-items: center;
                gap: 5px;
                text-align: center;
                justify-content: center;

                i {
                    font-size: 20px;
                }

                &:nth-of-type(1) {
                    background-color: $theme-primary;
                    color: #fff;
                    flex: 1;
                }
            }
        }

        .cnts {
            form {
                input {
                    border: 1px solid #e6e6e6;
                    background-color: #fff;
                    padding: 10px;
                    width: 100%;
                    border-radius: 5px;
                    margin-top: 10px;
                    font-size: 14px;
                }

                textarea {
                    height: 150px;
                    border: 1px solid #e6e6e6;
                    background-color: #fff;
                    padding: 10px;
                    width: 100%;
                    border-radius: 5px;
                    margin-top: 10px;
                    font-size: 14px;
                }

                button {
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .detail_page {
        .sld_box {
            padding: 40px 0 10px;

            img {
                height: 330px;
            }
        }
    }
}

@media (max-width: 767px) {
    .detail_page {
        .header {
            .d-flex {
                .align-items-end {
                    align-items: center !important;
                }
            }
        }

        .related {

            .sld {
                .slick-slide {
                    padding: 0 15px;
                }

                .slick-next {
                    right: -10px;
                }

                .slick-prev {
                    left: -10px;
                }
            }
        }
    }
}

@media (max-width: 550px) {
    .detail_page {
        .header {
            h1 {
                font-size: 22px;
            }

            h2 {
                font-size: 20px;
            }
        }

        .sld_box {
            img {
                height: 260px;
            }
        }

        .det_box {
            h2 {
                font-size: 18px;
            }

            .desc {
                h2 {
                    font-size: 18px;
                }
            }

            p {
                font-size: 14px;
            }

            .prdet {
                h3 {
                    font-size: 18px;
                }
            }

            .location {
                .map {
                    height: 300px;
                }
            }

            .ryt {
                .contact {
                    .d-flex {
                        a {
                            flex: 1;
                        }
                    }
                }
            }
        }
    }
}