.list_banner {
    background-image: url("../images/banner2.webp");
    background-size: cover;
    height: 400px;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: #fff;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    padding-top: 75px;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.75);
        z-index: -1;
        width: 100%;
        height: 100%;
    }


    .d-flex {
        font-size: 14px;

        a {
            color: #fff;
            text-decoration: none;
            transition: .2s all ease;

            &:hover {
                color: $theme-primary;
                transition: .2s all ease;
            }
        }

        .act {
            font-weight: 600;
            color: $theme-primary;
        }
    }

    h1 {
        text-transform: uppercase;
    }

    p {
        font-size: 18px;
        text-transform: uppercase;

        @media(max-width:767px) {
            font-size: 16px;
        }
    }

}


@media(max-width: 550px) {
    .list_banner {
        height: 340px;
    }
}