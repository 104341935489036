.browse_prop {
    padding: 60px 20px;



    .d-flex {
        gap: 20px;
        flex-wrap: wrap;

        .brwseprop {
            min-width: 320px;
            flex: 1;
            border: 1px solid $light-grey;
            border-radius: 15px;

            .head_bx {
                padding: 20px 25px 0 25px;
                border-bottom: 1px solid $light-grey;

                .icon_bx {
                    display: flex;
                    gap: 15px;
                    align-items: center;
                    margin-bottom: 20px;

                    i {
                        color: $theme-primary;
                        font-size: 24px;
                    }

                    h3 {
                        font-size: 20px;
                        margin-bottom: 0px;
                    }
                }

                .tabs {
                    display: flex;
                    flex-wrap: wrap;

                    button {
                        background-color: transparent;
                        padding: 5px 10px 15px;
                        border: none;
                        outline: none;
                        border-bottom: 1px solid transparent;
                        text-align: center;
                        font-size: 15px;
                        font-weight: 500;

                        &.active {
                            border-bottom: 1px solid $theme-primary;
                            color: $theme-primary;
                        }
                    }
                }

            }

            .cnt_bx {
                display: flex;
                flex-wrap: wrap;
                padding: 20px;
                gap: 10px;
            }

            .types {
                text-align: center;
                width: calc(33% - 6px);
                color: $text-color;
                text-decoration: none;
                padding: 10px 5px;
                border-radius: 8px;
                border: 1px solid $light-grey;

                h4 {
                    font-size: 13px;
                }

                span {
                    color: $light-text;
                    font-size: 12px;
                }
            }
        }
    }
}

@media(max-width: 550px) {
    .browse_prop {
        padding: 30px 20px;

        .d-flex {
            .brwseprop {
                min-width: 280px;

                .head_bx {
                    .icon_bx {
                        h3 {
                            font-size: 18px;
                        }
                    }

                    .tabs {
                        button {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}