.developers_slider_container {
    .swiper-initialized {
        overflow-y: unset;
        .swiper-slide {
            width: 255px;
            height: 145px;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #d3d3d3;
            transition: all 0.12s ease-out;
            background: #fff;
            &:hover {
                transform: scale(1.05);
                transition: all 0.2s ease-in;
                box-shadow: 0 0 40px rgba(0, 0, 0, 0.07);
                z-index: 1;
                position: relative;
            }
            a {
                width: 100%;
                img {
                    max-width: 100%;
                    max-height: 85px;
                    transition: all 0.5s ease 0s;
                }
            }
        }
    }
    .swiper {
        overflow-y: hidden;
        .swiper-pagination {
            bottom: -35px;
        }
    }
    &.slider_centered {
        .swiper-wrapper {
            justify-content: center;
        }
    }
}
