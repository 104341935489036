@import "../components/newscard";

.news_inner {
    padding: 120px 0 40px;

    .nws_main {
        background-color: #f9f9f9;
        padding: 80px;

        h2 {
            font-weight: 600;
        }

        img {
            width: 100%;
            max-height: 460px;
            object-fit: contain;
            margin-bottom: 20px;
        }

        .dte {
            margin-bottom: 10px;
            display: block;
        }

        h3 {
            margin-top: 15px;
        }

        p {
            font-size: em(20);
            margin-bottom: 10px;
            color: $light-text;
        }
    }

    .sld_news {
        padding: 60px 0 20px;

        .sld {
            .nws_box {
                &:hover {
                    transition: 0.3s all ease;
                    transform: translateY(-5px) scale(1.01);
                }
            }

            .slick-slide {
                padding: 10px;
            }

            .slick-next {
                right: -20px;
                color: #000;
                border-radius: 4px 0 0 4px;
                padding: 10px 5px;
                height: 40px;
                width: 30px;
                transition: 0.3s all ease;

                &:before {
                    content: "\f105";
                    font-family: "Font Awesome 5 Pro";
                    color: #000;
                    font-size: 28px;
                }

                &:hover {
                    &::before {
                        transition: 0.3s all ease;
                        color: $theme-primary;
                    }
                }
            }

            .slick-prev {
                left: -20px;
                border-radius: 0 4px 4px 0;
                padding: 10px 5px;
                z-index: 9;
                color: #000;
                height: 40px;
                width: 30px;
                transition: 0.3s all ease;

                &:before {
                    content: "\f104";
                    font-family: "Font Awesome 5 Pro";
                    color: #000;
                    font-size: 28px;
                }

                &:hover {
                    &::before {
                        color: $theme-primary;
                        transition: 0.3s all ease;
                    }
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .news_inner {
        .nws_main {
            padding: 40px;
        }
    }
}

@media (max-width: 767px) {
    .news_inner {
        .nws_main {
            padding: 30px;
        }
    }
}

@media (max-width: 550px) {
    .news_inner {
        .nws_main {
            padding: 20px;

            h2 {
                font-size: 20px;
            }

            h3 {
                font-size: 18px;
            }

            p {
                font-size: 14px;
            }
        }

        .sld_news {
            padding: 40px 0 20px;
        }
    }
}
