.loader_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 200px);
  margin: 0;
  #loader {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: 5px solid #ddd;
    border-top-color: $theme-primary;
    animation: rotate 1s infinite;
  }
  &:not(.rm_bg_primary_loader) {
    background-color: $theme-primary;
    #loader {
      border-top-color: #333;
    }
  }
  @keyframes rotate {
    100% {
      rotate: 360deg;
    }
  }
}
