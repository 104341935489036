@import "../components/banner";
@import "../components/browseproperties";
@import "../components/featuredproperties";
@import "../components/counter";
@import "../components/premiumdesign";
@import "../components/developers";
@import "../components/teams";
@import "../common/testimonials";
@import "../components/cta";
@import "../common/newshome";
@import "../common/realrestatedevelopers";

@media (max-width: 767px) {
    .section_heading {
        margin-bottom: 20px;
    }
}