.contact {
    .lft_cnt {
        padding: 60px 0;

        p {
            font-size: 14px;
        }

        .lcs {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
            align-items: center;
            margin-top: 15px;

            span {
                background-color: $theme-primary;
                padding: 5px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                color: #fff;
                width: 40px;
                height: 40px;
                font-size: 18px;
            }

            a {
                color: $theme-primary;
                text-decoration: none;
                font-weight: 600;
                font-size: 14px;
            }
        }
    }

    .cnt_frm {
        background-color: #f9f9f9;
        padding: 60px;
        height: 100%;

        button {
            width: 100%;
        }
    }
}

@media(max-width: 991px) {
    .contact {
        .cnt_frm {
            padding: 60px 30px;
        }
    }
}

@media(max-width: 550px) {
    .contact {
        .cnt_frm {
            padding: 60px 15px;
        }
    }
}