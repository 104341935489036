.nws_box {
    border-radius: 10px;
    border: 1px solid #e6e6e6;
    color: $text-color;
    text-decoration: none;
    display: block;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
    transition: 0.3s all ease;

    &:hover {
        transition: 0.3s all ease;
        transform: translateY(-5px) scale(1.01);
    }

    img {
        border-radius: 10px 10px 0 0;
        width: 100%;
        height: 240px;
    }

    .nws_cnt {
        padding: 20px;
        background-color: #f9f9f9;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 15px;
        border-radius: 0 0 10px 10px;

        h2 {
            font-size: em(24);
            margin-bottom: 0px;
        }

        p {
            font-size: 14px;
            color: $light-text;
        }

        .dte {
            font-size: 12px;
        }
    }
}

@media(max-width: 550px) {
    .nws_box {
        .nws_cnt {
            h2 {
                font-size: em(20);
            }
        }

        img {
            height: 200px;
        }
    }
}