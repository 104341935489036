.careers {
    .jobs_accordian {
        background: #fff;
        padding: 80px;
        border-radius: 30px;
        box-shadow: 2px 2px 54px rgba(0, 0, 0, 0.12);
        .accordion {
            .accordion-item {
                border: none;
                border-bottom: 1px solid #ddd;
                margin-bottom: 26px;
                .accordion-header {
                    button {
                        box-shadow: none;
                        font-size: em(10);
                        letter-spacing: 1px;
                        color: #000;
                        font-weight: 400;
                        margin-bottom: 12px;
                        i {
                            font-size: em(18);
                            color: $theme-primary;
                            margin-right: 20px;
                        }
                        .minus-img {
                            display: none;
                        }
                        .add-img {
                            display: block;
                        }
                        &:focus {
                            box-shadow: none;
                        }
                        &:not(.collapsed) {
                            background: transparent;
                            font-weight: 600;
                            .minus-img {
                                display: block;
                            }
                            .add-img {
                                display: none;
                            }
                        }
                        &:after {
                            display: none;
                        }
                    }
                }
                .accordion-body {
                    margin-left: 60px;
                    letter-spacing: 0.5px;
                    font-size: em(20);
                    font-weight: 400;
                    position: relative;
                    transition: all 0.5s ease-in-out;
                    margin-bottom: 10px;
                    line-height: 28px;
                    &:before {
                        content: "";
                        position: absolute;
                        width: 1px;
                        height: 70%;
                        background: $theme-primary;
                        left: 0;
                        top: 0;
                        margin: auto;
                        bottom: 0;
                    }
                    .job_list {
                        margin: 20px 0;
                        padding-left: 25px;
                        li {
                            list-style: circle;
                            border-bottom: none;
                            margin-bottom: 0px;
                            letter-spacing: 0.3px;
                        }
                    }
                }
            }
        }
        @media (max-width: 768px) {
            padding: 20px;
        }
        ul {
            margin-top: 38px;
            @media (max-width: 768px) {
                padding: 0;
            }
            li {
                &:not(:last-child) {
                    border-bottom: 1px solid #dddddd;
                    margin-bottom: 26px;
                }
                .ques_box {
                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }
                }
                .icon_box {
                    margin-right: 20px;
                    i {
                        cursor: pointer;
                    }
                }
                .ques {
                    font-size: em(22);
                    letter-spacing: 1px;
                    color: #000;
                    font-weight: 400;
                    margin-bottom: 12px;
                    cursor: pointer;
                }
                .ans {
                    letter-spacing: 0.5px;
                    font-size: em(20);
                    font-weight: 400;
                    position: relative;
                    transition: all 0.5s ease-in-out;
                    margin-left: 15px;
                    opacity: 0;
                    height: 0px;
                    margin-bottom: 10px;
                    opacity: 0;
                    visibility: hidden;
                    line-height: 28px;
                    height: 0px;
                    &:before {
                        content: "";
                        position: absolute;
                        width: 1px;
                        height: 70%;
                        background: #6345f6;
                        left: -15px;
                        top: 0;
                        margin: auto;
                        bottom: 0;
                    }
                }
                &.active {
                    .ques {
                        font-weight: 600;
                    }
                    .ans {
                        height: fit-content;
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
}
