.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f7f7;
}

.not-found-content {
    text-align: center;
}

.not-found-heading {
    font-size: 120px;
    color: #ff6f61;
    margin: 0;
}

.not-found-text {
    font-size: 24px;
    color: #333;
    margin-bottom: 40px;
}

@keyframes float {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0);
    }
}
