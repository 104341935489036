@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200&display=swap");
* {
  margin: 0;
  padding: 0;
  outline: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}

html {
  font-size: 14px;
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 0.88rem;
  color: #262c2e;
  letter-spacing: 0.25px;
  background-color: #fff;
}

img {
  max-width: 100%;
}

p {
  margin-bottom: 0;
}

.btn_yl {
  width: fit-content;
  border: none;
  outline: none;
  padding: 10px 25px;
  position: relative;
  background-color: #6e302f;
  color: #fff;
  font-size: 16px;
  z-index: 1;
  text-decoration: none;
}
.btn_yl::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: #e39392;
  width: 100%;
  height: 100%;
  max-width: 0;
  width: 100%;
  z-index: -1;
  transition: 0.3s all ease;
}
.btn_yl:not(.remove_overlay):hover::before {
  max-width: 100%;
  transition: 0.3s all ease;
}

.btn_bg_none {
  border: 2px solid #6e302f;
  outline: none;
  padding: 5px 10px;
  position: relative;
  transition: 0.3s all ease;
  background-color: transparent;
  color: #6e302f;
  font-size: 12px;
  z-index: 1;
  text-decoration: none;
  font-weight: 600;
}
.btn_bg_none:hover {
  background-color: #6e302f;
  color: #fff;
}

.primary_color {
  color: #6e302f;
}

.secondary_color {
  color: #e39392;
}

.primary_badge {
  background-color: #6e302f;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.875em;
}

.sec_badge {
  background-color: #43766c;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.875em;
}

.ter_badge {
  background-color: #ffb534;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.875em;
}

.info_badge {
  background-color: #0bbbff;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.875em;
}

.bg_primary {
  background-color: #6e302f;
}

.light_text {
  color: #707070;
}

.heading_line {
  flex: 1;
  position: relative;
}
.heading_line::before {
  content: "";
  position: absolute;
  width: calc(100% - 30px);
  height: 1px;
  background: #e6e6e6;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.hover_primary_color:hover {
  color: #6e302f;
}

.fs-14 {
  font-size: 0.875em;
}

.fw-4 {
  font-weight: 400;
}

.border_card {
  border-radius: 6px;
  border: 1px solid #e6e6e6;
}

.section_heading {
  color: #6e302f;
  display: flex;
  margin-bottom: 40px;
}

input {
  border: 1px solid #e6e6e6;
  background-color: #fff;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  margin-top: 10px;
  font-size: 14px;
}

textarea {
  height: 150px;
  border: 1px solid #e6e6e6;
  background-color: #fff;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  margin-top: 10px;
  font-size: 14px;
}

.custom_form_group {
  width: 33%;
}
.custom_form_group label {
  color: #6e302f;
  letter-spacing: 0.6px;
  text-align: left;
  width: 100%;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 12px;
}

.line-3-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-2-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.top_navbar {
  position: absolute;
  top: 0;
  z-index: 99;
  width: 100%;
  background: #fff;
  transition: 0.3s all ease;
  height: 75px;
}
.top_navbar nav {
  position: absolute;
  top: 0;
  z-index: 99;
  width: 100%;
  background: #fff;
  transition: 0.3s all ease;
  height: 75px;
}
.top_navbar nav.bg-fff {
  position: fixed;
  background-color: #fff;
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.18);
  transition: 0.3s all ease;
}
.top_navbar nav .wrapper {
  position: relative;
  height: 100%;
  line-height: 48px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
.top_navbar .logo {
  width: 90px;
}
.top_navbar .logo img {
  max-width: 85px;
}
.top_navbar .wrapper .logo a {
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  text-decoration: none;
}
.top_navbar .wrapper .nav-links {
  display: inline-flex;
  margin-bottom: 0px;
  /* margin-left: 10%; */
  padding-left: 0px;
}
.top_navbar .numb_nav {
  text-align: right;
  margin-left: 20px;
}
.top_navbar .down-an {
  vertical-align: middle;
}
.top_navbar .nav-ic {
  color: #cc9562;
}
.top_navbar .nav-span {
  display: block;
}
.top_navbar .nav-links li {
  list-style: none;
}
.top_navbar .nav-links li a i {
  vertical-align: text-top;
}
.top_navbar .nav-links li a,
.top_navbar .nav-links li button {
  color: #262c2e;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  padding: 25px 15px;
  transition: all 0.3s ease;
  border-bottom: 3px solid transparent;
}
.top_navbar .nav-links li a i {
  vertical-align: middle;
}
.top_navbar .nav-links li a:hover,
.top_navbar .nav-links li button:hover {
  color: #6e302f !important;
  border-bottom: 2px solid #6e302f !important;
}
.top_navbar .nav-links .mobile-item {
  display: none;
}
.top_navbar .nav-links .drop-menu {
  position: absolute;
  background-color: #fff;
  min-width: 200px;
  max-width: 380px;
  width: auto;
  line-height: 35px;
  top: 100px;
  opacity: 0;
  padding: 0px 0px 0px 0;
  visibility: hidden;
  border-width: 1px 1px 0 1px;
  border-color: #f8f8f9;
  border-style: solid;
}
.top_navbar .nvbxn {
  width: 200px;
}
.top_navbar .imgbxn {
  width: 110px;
  line-height: 18px !important;
}
.top_navbar .imgnv {
  padding: 0px !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #fff !important;
}
.top_navbar .imgnv:hover {
  color: #e36f1e !important;
}
.top_navbar .lstdrp {
  right: 0;
}
.top_navbar .dntebtn {
  background-color: #6e302f;
  border: 2px solid #6e302f !important;
  color: #fff !important;
  font-weight: 400 !important;
  padding: 10px 20px !important;
}
.top_navbar .dntebtn:hover {
  background-color: #fff !important;
  color: #6e302f !important;
}
.top_navbar .dntebtn2 {
  background-color: #fff;
  border: 2px solid #6e302f !important;
  color: #6e302f !important;
  font-weight: 400 !important;
  padding: 10px 20px !important;
  margin: 0 15px;
}
.top_navbar .dntebtn2:hover {
  background-color: #6e302f !important;
  color: #fff !important;
}
.top_navbar .nav-links li:hover .drop-menu,
.top_navbar .nav-links li:hover .mega-box {
  transition: all 0.3s ease;
  top: 76px;
  opacity: 1;
  visibility: visible;
}
.top_navbar .drop-menu li a {
  width: 100%;
  display: block;
  padding: 1px 20px;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #262c2e !important;
  background-color: #fff;
  text-align: left;
  border-bottom: 1px solid #f8f8f9;
}
.top_navbar .drop-menu li a:hover {
  text-decoration: none;
  border-bottom: 1px solid #f8f8f9 !important;
  background-color: #e6e6e6;
}
.top_navbar .megal {
  width: 65% !important;
  left: 100px;
}
.top_navbar .wrapper .btn.close-btn,
.top_navbar .wrapper .btn.menu-btn {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  display: none;
}
.top_navbar .wrapper .btn.close-btn {
  color: #6e302f;
  position: absolute;
  right: 10px;
  top: 10px;
}
.top_navbar .mygr {
  color: #38b87c;
  font-size: 16px;
  font-weight: 500;
}
@media screen and (max-width: 992px) {
  .top_navbar {
    /* custom scroll bar */
    /* .nav-links .mobile-item:hover{
      background: #3A3B3C;
    } */
  }
  .top_navbar .wrapper .btn {
    display: block;
    color: #20ad9e;
  }
  .top_navbar .corner-nav {
    width: 50px;
    height: 50px;
    bottom: -23%;
    left: 9%;
  }
  .top_navbar .menu-btn {
    margin-left: 0%;
    color: #6e302f !important;
    font-size: 28px !important;
  }
  .top_navbar nav .wrapper {
    padding: 0px 20px 0px 20px;
    justify-content: space-between;
  }
  .top_navbar .numb_nav {
    width: 50%;
    text-align: left;
    margin-top: 20px;
  }
  .top_navbar .wrapper .nav-links {
    position: fixed;
    height: 100vh;
    width: 100%;
    max-width: 320px;
    top: 0%;
    left: -120%;
    background: #fff;
    display: block;
    margin-left: 0%;
    padding: 50px 0px 0px;
    line-height: 50px;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.18);
    transition: all 0.3s ease;
    color: #fff;
  }
  .top_navbar .bordbt {
    border-bottom: 2px solid #353535;
  }
  .top_navbar ::-webkit-scrollbar {
    width: 10px;
  }
  .top_navbar ::-webkit-scrollbar-track {
    background: #242526;
  }
  .top_navbar ::-webkit-scrollbar-thumb {
    background: #3a3b3c;
  }
  .top_navbar #menu-btn:checked ~ .nav-links {
    left: 0%;
  }
  .top_navbar #menu-btn:checked ~ .btn.menu-btn {
    display: none;
  }
  .top_navbar #close-btn:checked ~ .btn.menu-btn {
    display: block;
  }
  .top_navbar .nav-links li a {
    padding: 0px 20px;
    display: block;
    font-size: 14px;
    border-radius: 0px;
    font-weight: 400;
    transition: 0.3s all ease;
    border-bottom: 1px solid #f8f8f9;
  }
  .top_navbar .let_anc {
    padding-left: 0px !important;
    margin: 20px auto 0px 28%;
  }
  .top_navbar .dntebtn {
    padding: 5px 20px !important;
  }
  .top_navbar .nav-links .drop-menu {
    position: relative;
    opacity: 1;
    top: 0px !important;
    left: 0%;
    visibility: visible;
    padding-left: 0px;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    box-shadow: none;
    transition: all 0.3s ease;
    background-color: transparent;
    border-radius: 0px;
    border-left: 0px;
    padding: 0px;
    border-right: 0px;
    background-color: #fff;
  }
  .top_navbar #showDrop:checked ~ .drop-menu,
.top_navbar #showMega:checked ~ .mega-box {
    max-height: 100%;
    transition: 0.3s all ease;
    left: 0px;
    top: 0px;
  }
  .top_navbar #showDrop2:checked ~ .drop-menu {
    max-height: 100%;
    transition: 0.3s all ease;
    left: 0px;
    top: 0px;
  }
  .top_navbar #showDrop3:checked ~ .drop-menu {
    max-height: 100%;
    transition: 0.3s all ease;
    left: 0px;
    top: 0px;
  }
  .top_navbar #showDrop4:checked ~ .drop-menu {
    max-height: 100%;
    transition: 0.3s all ease;
    left: 0px;
    top: 0px;
  }
  .top_navbar #showDrop5:checked ~ .drop-menu {
    max-height: 100%;
    transition: 0.3s all ease;
    left: 0px;
    top: 0px;
  }
  .top_navbar #showDropcom:checked ~ .drop-menu,
.top_navbar #showMegainvest:checked ~ .mega-box {
    max-height: 100%;
  }
  .top_navbar .nav-links .desktop-item {
    display: none;
  }
  .top_navbar .nav-links .mobile-item i {
    margin-left: auto;
  }
  .top_navbar .nav-links .mobile-item {
    display: flex;
    align-items: center;
    color: #102442;
    font-size: 14px;
    font-weight: 400;
    padding: 0px 10px;
    padding-left: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    border-bottom: 1px solid #f8f8f9;
  }
  .top_navbar .drop-menu li {
    margin: 0;
  }
  .top_navbar .drop-menu li a {
    font-size: 14px !important;
    color: #fff;
    border-bottom: 1px solid #f8f8f9 !important;
    padding: 6px 20px;
  }
  .top_navbar .drop-menu li a:hover {
    background-color: #fff;
    text-decoration: none;
    color: #0066b1;
  }
  .top_navbar .drop_a,
.top_navbar .drop_b {
    border-radius: 0px;
  }
  .top_navbar .drop-corner {
    display: none;
  }
  .top_navbar .megal {
    width: 100% !important;
  }
  .top_navbar .dntebtn,
.top_navbar .dntebtn2 {
    width: fit-content !important;
    padding: 0 20px !important;
    margin: 10px 15px 0;
  }
}
.top_navbar nav input {
  display: none;
}
.top_navbar .body-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  padding: 0 30px;
}
.top_navbar .body-text div {
  font-size: 45px;
  font-weight: 600;
}
@media (max-width: 550px) {
  .top_navbar .logo img {
    max-width: 64px;
  }
}
@media screen and (min-width: 992px) {
  .top_navbar.variant_2 {
    background: #f3f3f329;
  }
  .top_navbar.variant_2 nav {
    background: #f3f3f329;
    backdrop-filter: blur(30px) brightness(124%);
  }
  .top_navbar.variant_2 nav .nav-links li a {
    color: #fff;
  }
}

.loader_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 200px);
  margin: 0;
}
.loader_wrapper #loader {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 5px solid #ddd;
  border-top-color: #6e302f;
  animation: rotate 1s infinite;
}
.loader_wrapper:not(.rm_bg_primary_loader) {
  background-color: #6e302f;
}
.loader_wrapper:not(.rm_bg_primary_loader) #loader {
  border-top-color: #333;
}
@keyframes rotate {
  100% {
    rotate: 360deg;
  }
}

footer {
  background-color: #f9f9f9;
  border-top: 2px solid #e6e6e6;
}
footer img {
  max-width: 100px;
}
footer h3 {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 600;
}
footer p {
  font-size: 1.125em;
  color: #707070;
}
footer a {
  color: #707070;
  text-decoration: none;
  display: block;
  margin-top: 5px;
  font-size: 1em;
  transition: 0.3s all ease;
  width: fit-content;
}
footer a:hover {
  color: #6e302f;
  transition: 0.3s all ease;
}
footer .soc_bx {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 15px;
}
footer .soc_bx a {
  font-size: 22px;
}
footer .soc_bx a:nth-of-type(1) {
  color: #1877f2;
}
footer .soc_bx a:nth-of-type(2) {
  color: transparent;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
  background: -webkit-radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
  background-clip: text;
  -webkit-background-clip: text;
}
footer .soc_bx a:nth-of-type(3) {
  color: #00acee;
}
footer .soc_bx a:nth-of-type(4) {
  color: #c4302b;
}
footer .btm {
  border-top: 1px solid #e6e6e6;
  margin: 25px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
footer .btm .lft {
  display: flex;
  flex-wrap: wrap;
  gap: 15px 25px;
}

@media (max-width: 550px) {
  footer h3 {
    font-size: 18px;
  }
}
.banner_box {
  position: relative;
  height: 100vh;
  min-height: 500px;
  width: 100%;
}
.banner_box .slider_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.banner_box .slider_box .slick-slider {
  height: 100%;
  width: 100%;
  max-width: 100%;
}
.banner_box .slider_box .slick-slider .slick-list {
  height: 100%;
}
.banner_box .slider_box img {
  width: 100%;
  min-height: 100vh;
  /* min-height: 500px; */
  object-fit: cover;
  object-position: center;
}
.banner_box .slider_box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1;
}
.banner_box .slider_box .slick-dots {
  bottom: 15px !important;
  z-index: 999;
}
.banner_box .slider_box .slick-dots li button:before {
  color: #fff !important;
  width: 20px !important;
  height: 20px !important;
  font-size: 14px !important;
  opacity: 0.8 !important;
}
.banner_box .slider_box .slick-dots li.slick-active button:before {
  color: #6e302f !important;
  opacity: 1 !important;
}
.banner_box .bnr_cnt {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  max-width: 1120px;
  margin: auto;
  padding: 0 20px;
  color: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.banner_box .bnr_cnt h1 {
  font-size: 52px;
  max-width: 600px;
  width: 100%;
  margin: 75px 0 25px;
  color: #fff;
}
.banner_box .bnr_cnt .tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  padding-left: 20px;
}
.banner_box .bnr_cnt .tabs button {
  backdrop-filter: blur(30px) brightness(124%);
  padding: 5px 15px;
  font-size: 14px;
  border-radius: 5px 5px 0 0;
  border: 0;
  outline: none;
  background-color: transparent;
  color: #fff;
}
.banner_box .bnr_cnt .tabs button.active {
  background-color: #fff;
  color: #262c2e;
}
.banner_box .bnr_cnt .srch_bx {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 35px;
  backdrop-filter: blur(30px) brightness(124%);
  border-radius: 8px;
}
.banner_box .bnr_cnt .srch_bx select {
  width: calc(25% - 10px);
  min-width: 240px;
  flex: 1;
  padding: 10px 15px;
  border: 1px solid #a4a4a4;
  font-size: 16px;
  cursor: pointer;
}

@media (max-width: 991px) {
  .banner_box .bnr_cnt h1 {
    font-size: 42px;
  }
}
@media (max-width: 767px) {
  .banner_box .bnr_cnt h1 {
    font-size: 32px;
  }
}
@media (max-width: 550px) {
  .banner_box .bnr_cnt {
    top: 40px;
  }
  .banner_box .bnr_cnt h1 {
    font-size: 26px;
  }
  .banner_box .bnr_cnt .srch_bx {
    padding: 15px;
    gap: 10px;
  }
  .banner_box .bnr_cnt .srch_bx select,
.banner_box .bnr_cnt .srch_bx button {
    font-size: 14px;
  }
}
.search_bar_container {
  background: #fff;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 5px;
  min-height: 80px;
  padding: 10px;
  margin: 20px;
}
.search_bar_container .search_bar_wrapper {
  width: 100%;
  gap: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search_bar_container .custom_form_group {
  width: 33%;
}
.search_bar_container .custom_form_group label {
  color: #6e302f;
  letter-spacing: 0.6px;
  text-align: left;
  width: 100%;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 11px;
}
.search_bar_container .search_field {
  padding: 0 20px 0 5px;
  display: flex;
  align-items: center;
}
.search_bar_container .search_field i {
  color: #384347;
  margin-right: 10px;
  font-size: 18px;
  opacity: 0.3;
}
.search_bar_container .search_field input {
  height: 50px;
  border: none;
}
.search_bar_container .search_datepicker input {
  height: 38px;
}
.search_bar_container .search_datepicker input:focus {
  border-color: #e39392;
  box-shadow: none;
  border-width: 1px;
}
.search_bar_container .select_picker {
  color: #000;
}
.search_bar_container .search_bag_count {
  display: flex;
  align-items: center;
  padding: 0 20px 0 15px;
  width: 165px;
}
.search_bar_container .search_bag_count .bag_icon i {
  color: #384347;
  margin-right: 10px;
  font-size: 18px;
  opacity: 0.3;
}
.search_bar_container .search_bag_count .bags_count {
  color: #384347;
  font-size: 1.125em;
}
.search_bar_container .search_bag_count .bags_increment {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
}
.search_bar_container .search_bag_count .bags_increment i {
  cursor: pointer;
  color: #384347;
  font-size: 16px;
  opacity: 0.3;
}
.search_bar_container .proceed_search button {
  height: 50px;
  font-size: 1.25em;
  font-weight: 600;
}
@media (max-width: 768px) {
  .search_bar_container {
    width: 70%;
    flex-direction: column;
    justify-content: space-between;
    background: transparent;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(10px);
    background-color: #ff4f5212;
  }
  .search_bar_container .search_bar_wrapper {
    flex-flow: row wrap;
    margin-bottom: 10px;
  }
  .search_bar_container .custom_form_group {
    width: 100%;
  }
  .search_bar_container .custom_form_group label {
    color: #fff;
  }
  .search_bar_container .select_picker {
    width: 100%;
  }
  .search_bar_container .search_field {
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .search_bar_container .search_field input {
    height: 35px;
    width: 100%;
  }
  .search_bar_container .search_datepicker {
    background-color: #fff;
    border-radius: 5px;
    width: 100%;
  }
  .search_bar_container .search_bag_count {
    padding: 0px 4px;
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 10px;
    width: 49%;
    height: 35px;
    justify-content: space-between;
    padding: 0px 15px;
  }
  .search_bar_container .search_bag_count .bag_icon i {
    font-size: 14px;
  }
  .search_bar_container .search_bag_count .bags_count {
    font-size: 0.875em;
  }
  .search_bar_container .search_bag_count .bags_increment {
    margin-left: 10px;
  }
  .search_bar_container .search_bag_count .bags_increment i {
    font-size: 14px;
    margin-right: 0;
  }
  .search_bar_container .proceed_search {
    width: 100%;
  }
  .search_bar_container .proceed_search button {
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    margin: auto;
  }
}
@media (max-width: 480px) {
  .search_bar_container {
    width: 100%;
  }
  .search_bar_container .custom_form_group {
    width: 100%;
  }
}
@media (min-width: 480px) and (max-width: 576px) {
  .search_bar_container {
    width: 100%;
  }
  .search_bar_container .custom_form_group {
    width: 100%;
  }
}

.browse_prop {
  padding: 60px 20px;
}
.browse_prop .d-flex {
  gap: 20px;
  flex-wrap: wrap;
}
.browse_prop .d-flex .brwseprop {
  min-width: 320px;
  flex: 1;
  border: 1px solid #e6e6e6;
  border-radius: 15px;
}
.browse_prop .d-flex .brwseprop .head_bx {
  padding: 20px 25px 0 25px;
  border-bottom: 1px solid #e6e6e6;
}
.browse_prop .d-flex .brwseprop .head_bx .icon_bx {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 20px;
}
.browse_prop .d-flex .brwseprop .head_bx .icon_bx i {
  color: #6e302f;
  font-size: 24px;
}
.browse_prop .d-flex .brwseprop .head_bx .icon_bx h3 {
  font-size: 20px;
  margin-bottom: 0px;
}
.browse_prop .d-flex .brwseprop .head_bx .tabs {
  display: flex;
  flex-wrap: wrap;
}
.browse_prop .d-flex .brwseprop .head_bx .tabs button {
  background-color: transparent;
  padding: 5px 10px 15px;
  border: none;
  outline: none;
  border-bottom: 1px solid transparent;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
}
.browse_prop .d-flex .brwseprop .head_bx .tabs button.active {
  border-bottom: 1px solid #6e302f;
  color: #6e302f;
}
.browse_prop .d-flex .brwseprop .cnt_bx {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  gap: 10px;
}
.browse_prop .d-flex .brwseprop .types {
  text-align: center;
  width: calc(33% - 6px);
  color: #262c2e;
  text-decoration: none;
  padding: 10px 5px;
  border-radius: 8px;
  border: 1px solid #e6e6e6;
}
.browse_prop .d-flex .brwseprop .types h4 {
  font-size: 13px;
}
.browse_prop .d-flex .brwseprop .types span {
  color: #707070;
  font-size: 12px;
}

@media (max-width: 550px) {
  .browse_prop {
    padding: 30px 20px;
  }
  .browse_prop .d-flex .brwseprop {
    min-width: 280px;
  }
  .browse_prop .d-flex .brwseprop .head_bx .icon_bx h3 {
    font-size: 18px;
  }
  .browse_prop .d-flex .brwseprop .head_bx .tabs button {
    font-size: 14px;
  }
}
.card_box {
  max-width: 400px;
  flex: 1;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  overflow: hidden;
}
.card_box .slider_box {
  position: relative;
  height: 260px;
  border-radius: 10px;
  width: 100%;
}
.card_box .slider_box .slid {
  position: absolute;
  top: 0;
  left: 0;
  height: 260px;
  width: 100%;
}
.card_box .slider_box .slid img {
  height: 260px;
  width: 100%;
}
.card_box .slider_box .slid .slick-dots {
  bottom: 15px !important;
  text-align: right !important;
  padding: 0 20px;
  z-index: 1;
}
.card_box .slider_box .slid .slick-dots li button:before {
  color: #fff !important;
  width: 12px !important;
  height: 12px !important;
  font-size: 12px !important;
  opacity: 0.8 !important;
}
.card_box .slider_box .slid .slick-dots li.slick-active button:before {
  color: #6e302f !important;
  opacity: 1 !important;
}
.card_box .slider_box .slid .slick-slide {
  padding: 0 !important;
}
.card_box .slider_box .sell {
  position: absolute;
  top: 25px;
  border-radius: 0;
  border-top-right-radius: 10px;
}
.card_box .slider_box .select_box {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 3px 10px;
  backdrop-filter: blur(30px) brightness(124%);
  border-radius: 5px 0 0 5px;
  position: absolute;
  top: 25px;
  right: 0px;
}
.card_box .slider_box .select_box button {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 15px;
}
.card_box .content_box {
  padding: 20px 10px;
}
.card_box .content_box .tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 1.125em;
}
.card_box .content_box .tags .card_lower {
  color: #262c2e;
  text-decoration: none;
  transition: 0.3s all ease;
}
.card_box .content_box .tags .card_lower:hover {
  transition: 0.3s all ease;
  color: #6e302f;
}
.card_box .content_box .tags span {
  margin: 0 5px;
}
.card_box .content_box .name {
  font-size: 24px;
  font-weight: 500;
  color: #262c2e;
  text-decoration: none;
  position: relative;
}
.card_box .content_box .name::before {
  content: "";
  height: 2px;
  width: 100%;
  max-width: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #6e302f;
  transition: 0.3s all ease;
}
.card_box .content_box .name:hover::before {
  max-width: 100%;
  transition: 0.3s all ease;
}
.card_box .content_box p {
  font-size: 12px;
  padding: 15px 0;
  margin-top: 0;
}
.card_box .content_box h3 {
  font-size: 1.5em;
  color: #6e302f;
  font-weight: 600;
}
.card_box .content_box h3 span {
  color: #707070;
  font-size: 0.875em;
  font-weight: 500;
}
.card_box .content_box .price_box {
  border-top: 1px solid #e6e6e6;
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}
.card_box .content_box .price_box .haves {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.card_box .content_box .price_box .haves > div {
  font-size: 14px;
}
.card_box .content_box .price_box .haves > div i {
  font-size: 16px;
  margin-right: 5px;
}

@media (max-width: 767px) {
  .card_box {
    max-width: 100%;
  }
}
@media (max-width: 550px) {
  .card_box {
    min-width: 280px;
  }
  .card_box .slider_box {
    height: 200px;
  }
  .card_box .slider_box .slid {
    height: 200px;
  }
  .card_box .slider_box .slid img {
    height: 200px;
  }
  .card_box .content_box .tags {
    font-size: 14px;
  }
  .card_box .content_box .name {
    font-size: 18px;
  }
}
.featured_prop .d-flex {
  gap: 30px 15px;
}
.featured_prop .sld .slick-slide {
  padding: 0 10px;
}
.featured_prop .sld .slick-next {
  right: -20px;
  color: #000;
  border-radius: 4px 0 0 4px;
  padding: 10px 5px;
  height: 40px;
  width: 30px;
  transition: 0.3s all ease;
}
.featured_prop .sld .slick-next:before {
  content: "\f105";
  font-family: "Font Awesome 5 Pro";
  color: #000;
  font-size: 28px;
}
.featured_prop .sld .slick-next:hover::before {
  transition: 0.3s all ease;
  color: #6e302f;
}
.featured_prop .sld .slick-prev {
  left: -20px;
  border-radius: 0 4px 4px 0;
  padding: 10px 5px;
  z-index: 9;
  color: #000;
  height: 40px;
  width: 30px;
  transition: 0.3s all ease;
}
.featured_prop .sld .slick-prev:before {
  content: "\f104";
  font-family: "Font Awesome 5 Pro";
  color: #000;
  font-size: 28px;
}
.featured_prop .sld .slick-prev:hover::before {
  color: #6e302f;
  transition: 0.3s all ease;
}

@media (max-width: 767px) {
  .featured_prop .sld .slick-slide {
    padding: 0 15px;
  }
  .featured_prop .sld .slick-next {
    right: -10px;
  }
  .featured_prop .sld .slick-prev {
    left: -10px;
  }
}
@media (max-width: 550px) {
  .featured_prop .d-flex {
    justify-content: center;
  }
}
.counter {
  background-color: #6e302f;
  padding: 50px 0;
  text-align: center;
  margin: 50px 0 0;
}
.counter .contr {
  display: flex;
  flex-direction: column;
}
.counter .contr i {
  color: #fff;
  font-size: 48px;
  margin-bottom: 10px;
}
.counter .contr h2 {
  font-size: 2.125em;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0;
}
.counter .contr p {
  font-size: 1em;
  font-weight: 300;
  color: #f5efef;
}

@media (max-width: 991px) {
  .counter .contr i {
    font-size: 60px;
  }
  .counter .contr h2 {
    font-size: 1.625em;
  }
}
@media (max-width: 550px) {
  .counter {
    padding: 30px 0;
  }
  .counter .contr i {
    font-size: 36px;
  }
  .counter .contr h2 {
    font-size: 1.5em;
  }
  .counter .contr p {
    font-size: 1.0625em;
  }
}
.premium_design {
  background-color: #edf9f9;
  padding: 40px 20px 60px 60px;
  border-radius: 12px;
  margin: 60px 0;
}
.premium_design .d-flex {
  max-width: 1100px;
  margin: auto;
}
.premium_design .d-flex .yl_bx {
  position: relative;
}
.premium_design .d-flex .yl_bx .img_wrapper {
  border-radius: 6px;
  overflow: hidden;
}
.premium_design .d-flex .yl_bx::before {
  content: "";
  position: absolute;
  left: -15px;
  top: 15px;
  background-color: #6e302f;
  width: 100%;
  height: 100%;
  border-radius: 6px;
}
.premium_design .d-flex .yl_bx img {
  position: relative;
  z-index: 1;
}
.premium_design .d-flex .cnt_bx h2 {
  font-size: 30px;
  font-weight: 600;
}
.premium_design .d-flex .cnt_bx p {
  font-size: 1em;
  margin-bottom: 5px;
  color: #707070;
}
.premium_design .d-flex .cnt_bx button {
  padding: 12px 25px;
}

@media (max-width: 991px) {
  .premium_design .d-flex .cnt_bx h2 {
    font-size: 26px;
  }
}
@media (max-width: 767px) {
  .premium_design .d-flex .cnt_bx {
    margin-top: 20px;
  }
}
@media (max-width: 550px) {
  .premium_design {
    padding: 20px 20px 20px 30px;
    margin: 30px 0;
  }
  .premium_design .d-flex .cnt_bx h2 {
    font-size: 20px;
  }
}
.developers_slider_container .swiper-initialized {
  overflow-y: unset;
}
.developers_slider_container .swiper-initialized .swiper-slide {
  width: 255px;
  height: 145px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d3d3d3;
  transition: all 0.12s ease-out;
  background: #fff;
}
.developers_slider_container .swiper-initialized .swiper-slide:hover {
  transform: scale(1.05);
  transition: all 0.2s ease-in;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.07);
  z-index: 1;
  position: relative;
}
.developers_slider_container .swiper-initialized .swiper-slide a {
  width: 100%;
}
.developers_slider_container .swiper-initialized .swiper-slide a img {
  max-width: 100%;
  max-height: 85px;
  transition: all 0.5s ease 0s;
}
.developers_slider_container .swiper {
  overflow-y: hidden;
}
.developers_slider_container .swiper .swiper-pagination {
  bottom: -35px;
}
.developers_slider_container.slider_centered .swiper-wrapper {
  justify-content: center;
}

.team {
  padding-bottom: 60px;
}
.team .team_box {
  padding: 15px;
  border-radius: 10px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #e6e6e6;
  transition: 0.4s all ease;
}
.team .team_box img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 220px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.team .team_box .tm-cnt {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.team .team_box .tm-cnt h4 {
  font-size: 1.1875em;
  font-weight: 600;
  text-wrap: nowrap;
}
.team .team_box .tm-cnt h5 {
  font-size: 1.0625em;
}
.team .team_box .tm-cnt span {
  font-size: 0.875em;
  margin-bottom: 20px;
}
.team .team_box .scbx {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
}
.team .team_box .scbx a {
  color: #6e302f;
  text-decoration: none;
  font-size: 22px;
  transition: 0.3s all ease;
}
.team .team_box .scbx a:hover {
  transform: scale(1.12);
  transition: 0.3s all ease;
}
.team .team_box:hover {
  transition: 0.4s all ease;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  transform: scale(1.01);
}

.testimonials .slick-slide {
  padding: 0 10px;
}
.testimonials .slick-list {
  padding: 60px 0;
}
.testimonials .slick-prev:before,
.testimonials .slick-next:before {
  color: #6e302f !important;
}
.testimonials .slick-next {
  right: -10px !important;
}
.testimonials .slick-prev {
  left: -10px !important;
  z-index: 999;
}
.testimonials .rvs {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 60px 30px 30px 30px;
  position: relative;
  max-width: 500px;
  margin: 40px auto 0;
  min-height: 200px;
}
.testimonials .rvs .rvimg {
  position: absolute;
  top: -90px;
  left: 0;
  right: 0;
  margin: auto;
  width: 140px;
  height: 140px;
  border-radius: 100%;
}
.testimonials .rvs .rvp {
  text-align: center;
  font-style: italic;
  font-size: 14px;
}
.testimonials .rvs .rvp2 {
  text-align: center;
  font-style: italic;
  font-size: 14px;
  font-weight: 600;
  margin-top: 15px;
}

@media (max-width: 550px) {
  .testimonials .testis {
    padding: 40px 30px 30px;
  }
  .testimonials .testis .user_icon {
    width: 90px;
    height: 90px;
    top: -45px;
    font-size: 30px;
  }
  .testimonials .testis span {
    font-size: 40px;
  }
  .testimonials .rvs {
    padding: 60px 10px 20px;
  }
  .testimonials .rvs .rvimg {
    width: 100px;
    height: 100px;
    top: -50px;
  }
}
.cta {
  padding: 0 0 40px;
}
.cta .cta_main {
  background-color: #f9f9f9;
  padding: 30px;
  text-align: center;
  border-radius: 10px;
}
.cta .cta_main p {
  color: #707070;
  font-size: 14px;
  max-width: 900px;
  margin: 20px auto 0;
}
.cta .cta_main .consult-btn {
  cursor: pointer;
  display: block;
  text-decoration: none;
  margin: 25px auto 0;
  padding: 10px 20px;
  border: 2px solid #6e302f;
  transition: 0.3s all ease;
  background-color: #6e302f;
  color: #fff;
  width: fit-content;
  font-size: 16px;
}
.cta .cta_main .consult-btn:hover {
  background-color: #f9f9f9;
  color: #6e302f;
}

.ctamodal .modal-body {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.ctamodal .cntfrm {
  padding-top: 50px;
}
.ctamodal .cntfrm > img {
  position: absolute;
  top: -50px;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background: #fff;
  left: 0;
  right: 0;
  margin: auto;
  padding: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.ctamodal .cntfrm h3 {
  text-align: center;
  text-transform: uppercase;
  color: #6e302f;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
}
.ctamodal .cntfrm h3::before {
  content: "";
  height: 1px;
  flex: 1;
  background-color: #e6e6e6;
}
.ctamodal .cntfrm h3::after {
  content: "";
  height: 1px;
  flex: 1;
  background-color: #e6e6e6;
}

.news {
  padding-bottom: 60px;
}
.news .d-flex {
  flex-wrap: wrap;
  gap: 25px;
}
.news .d-flex .news_box {
  display: flex;
  gap: 0;
  color: #262c2e;
  text-decoration: none;
  border-radius: 10px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  border: 1px solid #e6e6e6;
  overflow: hidden;
}
.news .d-flex .news_box .img_box {
  height: 160px;
  min-width: 140px;
  max-width: 140px;
}
.news .d-flex .news_box .img_box img {
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 100%;
}
.news .d-flex .news_box .news_cont {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.news .d-flex .news_box .news_cont h3 {
  font-size: 18px;
  font-weight: 600;
  color: #262c2e;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  height: 21px;
  overflow: hidden;
  transition: 0.3s all ease;
}
.news .d-flex .news_box .news_cont .desc {
  padding: 12px 12px 12px 0;
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #707070;
  text-overflow: ellipsis;
  font-size: 1em;
  height: 46px;
  overflow: hidden;
}
.news .d-flex .news_box .news_cont .date {
  font-size: 0.875em;
  color: #707070;
}
.news .d-flex .news_box:hover .news_cont h3 {
  color: #6e302f;
}

@media (max-width: 550px) {
  .news .d-flex .news_box img {
    width: 90px;
  }
  .news .d-flex .news_box .news_cont h3 {
    font-size: 16px;
  }
  .news .d-flex .news_box .news_cont .desc {
    padding: 0;
    height: 46px;
    margin-bottom: 0px;
    font-size: 14px;
  }
}
.real_estate_developers {
  padding: 50px 0 0;
}

@media (max-width: 767px) {
  .section_heading {
    margin-bottom: 20px;
  }
}
.banner_box {
  position: relative;
  height: 100vh;
  min-height: 500px;
  width: 100%;
}
.banner_box .slider_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.banner_box .slider_box .slick-slider {
  height: 100%;
  width: 100%;
  max-width: 100%;
}
.banner_box .slider_box .slick-slider .slick-list {
  height: 100%;
}
.banner_box .slider_box img {
  width: 100%;
  min-height: 100vh;
  /* min-height: 500px; */
  object-fit: cover;
  object-position: center;
}
.banner_box .slider_box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1;
}
.banner_box .slider_box .slick-dots {
  bottom: 15px !important;
  z-index: 999;
}
.banner_box .slider_box .slick-dots li button:before {
  color: #fff !important;
  width: 20px !important;
  height: 20px !important;
  font-size: 14px !important;
  opacity: 0.8 !important;
}
.banner_box .slider_box .slick-dots li.slick-active button:before {
  color: #6e302f !important;
  opacity: 1 !important;
}
.banner_box .bnr_cnt {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  max-width: 1120px;
  margin: auto;
  padding: 0 20px;
  color: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.banner_box .bnr_cnt h1 {
  font-size: 52px;
  max-width: 600px;
  width: 100%;
  margin: 75px 0 25px;
  color: #fff;
}
.banner_box .bnr_cnt .tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  padding-left: 20px;
}
.banner_box .bnr_cnt .tabs button {
  backdrop-filter: blur(30px) brightness(124%);
  padding: 5px 15px;
  font-size: 14px;
  border-radius: 5px 5px 0 0;
  border: 0;
  outline: none;
  background-color: transparent;
  color: #fff;
}
.banner_box .bnr_cnt .tabs button.active {
  background-color: #fff;
  color: #262c2e;
}
.banner_box .bnr_cnt .srch_bx {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 35px;
  backdrop-filter: blur(30px) brightness(124%);
  border-radius: 8px;
}
.banner_box .bnr_cnt .srch_bx select {
  width: calc(25% - 10px);
  min-width: 240px;
  flex: 1;
  padding: 10px 15px;
  border: 1px solid #a4a4a4;
  font-size: 16px;
  cursor: pointer;
}

@media (max-width: 991px) {
  .banner_box .bnr_cnt h1 {
    font-size: 42px;
  }
}
@media (max-width: 767px) {
  .banner_box .bnr_cnt h1 {
    font-size: 32px;
  }
}
@media (max-width: 550px) {
  .banner_box .bnr_cnt {
    top: 40px;
  }
  .banner_box .bnr_cnt h1 {
    font-size: 26px;
  }
  .banner_box .bnr_cnt .srch_bx {
    padding: 15px;
    gap: 10px;
  }
  .banner_box .bnr_cnt .srch_bx select,
.banner_box .bnr_cnt .srch_bx button {
    font-size: 14px;
  }
}
.search_bar_container {
  background: #fff;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 5px;
  min-height: 80px;
  padding: 10px;
  margin: 20px;
}
.search_bar_container .search_bar_wrapper {
  width: 100%;
  gap: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search_bar_container .custom_form_group {
  width: 33%;
}
.search_bar_container .custom_form_group label {
  color: #6e302f;
  letter-spacing: 0.6px;
  text-align: left;
  width: 100%;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 11px;
}
.search_bar_container .search_field {
  padding: 0 20px 0 5px;
  display: flex;
  align-items: center;
}
.search_bar_container .search_field i {
  color: #384347;
  margin-right: 10px;
  font-size: 18px;
  opacity: 0.3;
}
.search_bar_container .search_field input {
  height: 50px;
  border: none;
}
.search_bar_container .search_datepicker input {
  height: 38px;
}
.search_bar_container .search_datepicker input:focus {
  border-color: #e39392;
  box-shadow: none;
  border-width: 1px;
}
.search_bar_container .select_picker {
  color: #000;
}
.search_bar_container .search_bag_count {
  display: flex;
  align-items: center;
  padding: 0 20px 0 15px;
  width: 165px;
}
.search_bar_container .search_bag_count .bag_icon i {
  color: #384347;
  margin-right: 10px;
  font-size: 18px;
  opacity: 0.3;
}
.search_bar_container .search_bag_count .bags_count {
  color: #384347;
  font-size: 1.125em;
}
.search_bar_container .search_bag_count .bags_increment {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
}
.search_bar_container .search_bag_count .bags_increment i {
  cursor: pointer;
  color: #384347;
  font-size: 16px;
  opacity: 0.3;
}
.search_bar_container .proceed_search button {
  height: 50px;
  font-size: 1.25em;
  font-weight: 600;
}
@media (max-width: 768px) {
  .search_bar_container {
    width: 70%;
    flex-direction: column;
    justify-content: space-between;
    background: transparent;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(10px);
    background-color: #ff4f5212;
  }
  .search_bar_container .search_bar_wrapper {
    flex-flow: row wrap;
    margin-bottom: 10px;
  }
  .search_bar_container .custom_form_group {
    width: 100%;
  }
  .search_bar_container .custom_form_group label {
    color: #fff;
  }
  .search_bar_container .select_picker {
    width: 100%;
  }
  .search_bar_container .search_field {
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .search_bar_container .search_field input {
    height: 35px;
    width: 100%;
  }
  .search_bar_container .search_datepicker {
    background-color: #fff;
    border-radius: 5px;
    width: 100%;
  }
  .search_bar_container .search_bag_count {
    padding: 0px 4px;
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 10px;
    width: 49%;
    height: 35px;
    justify-content: space-between;
    padding: 0px 15px;
  }
  .search_bar_container .search_bag_count .bag_icon i {
    font-size: 14px;
  }
  .search_bar_container .search_bag_count .bags_count {
    font-size: 0.875em;
  }
  .search_bar_container .search_bag_count .bags_increment {
    margin-left: 10px;
  }
  .search_bar_container .search_bag_count .bags_increment i {
    font-size: 14px;
    margin-right: 0;
  }
  .search_bar_container .proceed_search {
    width: 100%;
  }
  .search_bar_container .proceed_search button {
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    margin: auto;
  }
}
@media (max-width: 480px) {
  .search_bar_container {
    width: 100%;
  }
  .search_bar_container .custom_form_group {
    width: 100%;
  }
}
@media (min-width: 480px) and (max-width: 576px) {
  .search_bar_container {
    width: 100%;
  }
  .search_bar_container .custom_form_group {
    width: 100%;
  }
}

.news {
  padding-bottom: 60px;
}
.news .d-flex {
  flex-wrap: wrap;
  gap: 25px;
}
.news .d-flex .news_box {
  display: flex;
  gap: 0;
  color: #262c2e;
  text-decoration: none;
  border-radius: 10px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  border: 1px solid #e6e6e6;
  overflow: hidden;
}
.news .d-flex .news_box .img_box {
  height: 160px;
  min-width: 140px;
  max-width: 140px;
}
.news .d-flex .news_box .img_box img {
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 100%;
}
.news .d-flex .news_box .news_cont {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.news .d-flex .news_box .news_cont h3 {
  font-size: 18px;
  font-weight: 600;
  color: #262c2e;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  height: 21px;
  overflow: hidden;
  transition: 0.3s all ease;
}
.news .d-flex .news_box .news_cont .desc {
  padding: 12px 12px 12px 0;
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #707070;
  text-overflow: ellipsis;
  font-size: 1em;
  height: 46px;
  overflow: hidden;
}
.news .d-flex .news_box .news_cont .date {
  font-size: 0.875em;
  color: #707070;
}
.news .d-flex .news_box:hover .news_cont h3 {
  color: #6e302f;
}

@media (max-width: 550px) {
  .news .d-flex .news_box img {
    width: 90px;
  }
  .news .d-flex .news_box .news_cont h3 {
    font-size: 16px;
  }
  .news .d-flex .news_box .news_cont .desc {
    padding: 0;
    height: 46px;
    margin-bottom: 0px;
    font-size: 14px;
  }
}
.custom_image_gallery .image-gallery-icon:hover {
  color: #6e302f;
}
.custom_image_gallery .image-gallery-thumbnail.active,
.custom_image_gallery .image-gallery-thumbnail:focus,
.custom_image_gallery .image-gallery-thumbnail:hover {
  border: 2px solid #6e302f;
}
.custom_image_gallery .image-gallery-thumbnail .image-gallery-thumbnail-image {
  height: 100%;
}
.custom_image_gallery .image-gallery-thumbnail .image-gallery-thumbnail-inner {
  height: 70px;
}

.detail_page {
  max-width: 95%;
  margin: 80px auto 0;
  padding: 20px;
}
.detail_page h2 {
  margin-bottom: 15px;
}
.detail_page .header {
  margin-bottom: 20px;
}
.detail_page .header button {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 14px;
  background-color: #f9f9f9;
  padding: 4px 10px;
  border-radius: 6px;
  transition: 0.3s all ease;
}
.detail_page .header button:hover {
  color: #6e302f;
  transition: 0.3s all ease;
}
.detail_page .header a {
  color: #262c2e;
  text-decoration: none;
}
.detail_page .header a:hover {
  color: #262c2e;
}
.detail_page .header .d-flex {
  gap: 10px;
}
.detail_page .header .d-flex .prp {
  border-left: 1px solid #e6e6e6;
  padding: 0 10px;
}
.detail_page .sld_box {
  padding: 40px 0 60px;
}
.detail_page .sld_box img {
  object-fit: cover;
}
.detail_page .sld_box .slick-next {
  right: 0;
  background-color: #f9f9f9;
  color: #000;
  border-radius: 4px 0 0 4px;
  padding: 10px 5px;
  height: 40px;
  width: 30px;
  transition: 0.3s all ease;
}
.detail_page .sld_box .slick-next:before {
  content: "\f105";
  font-family: "Font Awesome 5 Pro";
  color: #000;
}
.detail_page .sld_box .slick-next:hover::before {
  transition: 0.3s all ease;
  color: #6e302f;
}
.detail_page .sld_box .slick-prev {
  left: 0;
  background-color: #f9f9f9;
  border-radius: 0 4px 4px 0;
  padding: 10px 5px;
  z-index: 9;
  color: #000;
  height: 40px;
  width: 30px;
  transition: 0.3s all ease;
}
.detail_page .sld_box .slick-prev:before {
  content: "\f104";
  font-family: "Font Awesome 5 Pro";
  color: #000;
}
.detail_page .sld_box .slick-prev:hover::before {
  color: #6e302f;
  transition: 0.3s all ease;
}
.detail_page .det_box .project_literature h2 {
  margin-bottom: 10px;
  font-size: 1.375em;
  color: #6e302f;
}
.detail_page .det_box .project_literature p {
  font-size: 1.125em;
  letter-spacing: 0.4px;
  color: #707070;
}
.detail_page .det_box .project_literature .prdet {
  padding: 40px 0;
}
.detail_page .det_box .project_literature .prdet h3 {
  font-size: 20px;
}
.detail_page .det_box .project_literature .prdet .mndet {
  gap: 15px 30px;
  justify-content: space-between;
}
.detail_page .det_box .project_literature .prdet .mndet > div {
  border-bottom: 1px solid #e6e6e6;
  padding: 10px 0;
  gap: 0 15px;
  min-width: 280px;
  max-width: 360px;
  flex: 1;
}
.detail_page .det_box .project_literature .prdet .mndet > div i {
  font-size: 22px;
}
.detail_page .det_box .project_literature .prdet .mndet > div .d-flex {
  width: 100%;
}
.detail_page .det_box .project_literature .prdet .mndet > div .d-flex span {
  font-size: 15px;
}
.detail_page .det_box .videocontainer video {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.detail_page .det_box .location .map {
  width: 100%;
  border: 0;
  height: 400px;
}
.detail_page .det_box .ryt .useful {
  padding: 0px;
  border: 1px solid #e6e6e6;
  background-color: #f9f9f9;
  border-radius: 6px;
}
.detail_page .det_box .ryt .useful h3 {
  padding: 10px;
  text-align: center;
  font-size: 20px;
  border-bottom: 1px solid #e6e6e6;
}
.detail_page .det_box .ryt .useful > div {
  padding: 20px;
}
.detail_page .det_box .ryt .useful > div a {
  display: block;
  color: #262c2e;
  text-decoration: none;
  font-size: 14px;
}
.detail_page .det_box .ryt .useful > div a:not(:nth-last-of-type(1)) {
  margin-bottom: 5px;
}
.detail_page .det_box .ryt .useful > div a:hover {
  color: #6e302f;
}
.detail_page .related {
  padding: 0 0 60px;
}
.detail_page .related .sld .slick-slide {
  padding: 0 10px;
}
.detail_page .related .sld .slick-next {
  right: -20px;
  color: #000;
  border-radius: 4px 0 0 4px;
  padding: 10px 5px;
  height: 40px;
  width: 30px;
  transition: 0.3s all ease;
}
.detail_page .related .sld .slick-next:before {
  content: "\f105";
  font-family: "Font Awesome 5 Pro";
  color: #000;
  font-size: 28px;
}
.detail_page .related .sld .slick-next:hover::before {
  transition: 0.3s all ease;
  color: #6e302f;
}
.detail_page .related .sld .slick-prev {
  left: -20px;
  border-radius: 0 4px 4px 0;
  padding: 10px 5px;
  z-index: 9;
  color: #000;
  height: 40px;
  width: 30px;
  transition: 0.3s all ease;
}
.detail_page .related .sld .slick-prev:before {
  content: "\f104";
  font-family: "Font Awesome 5 Pro";
  color: #000;
  font-size: 28px;
}
.detail_page .related .sld .slick-prev:hover::before {
  color: #6e302f;
  transition: 0.3s all ease;
}
.detail_page .contact {
  padding: 20px;
  border: 1px solid #e6e6e6;
  background-color: #f9f9f9;
  border-radius: 10px;
}
.detail_page .contact .d-flex {
  gap: 10px 15px;
}
.detail_page .contact .d-flex a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 5px;
  text-align: center;
  justify-content: center;
}
.detail_page .contact .d-flex a i {
  font-size: 20px;
}
.detail_page .contact .d-flex a:nth-of-type(1) {
  background-color: #6e302f;
  color: #fff;
  flex: 1;
}
.detail_page .contact .cnts form input {
  border: 1px solid #e6e6e6;
  background-color: #fff;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  margin-top: 10px;
  font-size: 14px;
}
.detail_page .contact .cnts form textarea {
  height: 150px;
  border: 1px solid #e6e6e6;
  background-color: #fff;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  margin-top: 10px;
  font-size: 14px;
}
.detail_page .contact .cnts form button {
  width: 100%;
}

@media (max-width: 991px) {
  .detail_page .sld_box {
    padding: 40px 0 10px;
  }
  .detail_page .sld_box img {
    height: 330px;
  }
}
@media (max-width: 767px) {
  .detail_page .header .d-flex .align-items-end {
    align-items: center !important;
  }
  .detail_page .related .sld .slick-slide {
    padding: 0 15px;
  }
  .detail_page .related .sld .slick-next {
    right: -10px;
  }
  .detail_page .related .sld .slick-prev {
    left: -10px;
  }
}
@media (max-width: 550px) {
  .detail_page .header h1 {
    font-size: 22px;
  }
  .detail_page .header h2 {
    font-size: 20px;
  }
  .detail_page .sld_box img {
    height: 260px;
  }
  .detail_page .det_box h2 {
    font-size: 18px;
  }
  .detail_page .det_box .desc h2 {
    font-size: 18px;
  }
  .detail_page .det_box p {
    font-size: 14px;
  }
  .detail_page .det_box .prdet h3 {
    font-size: 18px;
  }
  .detail_page .det_box .location .map {
    height: 300px;
  }
  .detail_page .det_box .ryt .contact .d-flex a {
    flex: 1;
  }
}
.list_banner {
  background-image: url("../images/banner2.webp");
  background-size: cover;
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #fff;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  padding-top: 75px;
}
.list_banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: -1;
  width: 100%;
  height: 100%;
}
.list_banner .d-flex {
  font-size: 14px;
}
.list_banner .d-flex a {
  color: #fff;
  text-decoration: none;
  transition: 0.2s all ease;
}
.list_banner .d-flex a:hover {
  color: #6e302f;
  transition: 0.2s all ease;
}
.list_banner .d-flex .act {
  font-weight: 600;
  color: #6e302f;
}
.list_banner h1 {
  text-transform: uppercase;
}
.list_banner p {
  font-size: 18px;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .list_banner p {
    font-size: 16px;
  }
}

@media (max-width: 550px) {
  .list_banner {
    height: 340px;
  }
}
.listing .container {
  padding: 40px 20px;
}
.listing .container .lft_cards {
  gap: 20px;
}
.listing .container .lft_cards .card_box {
  min-width: 260px;
}
.listing .container .lft_cards .nodatafound {
  display: flex;
  flex-direction: column;
  gap: 18px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.listing .container .lft_cards .nodatafound > img {
  max-width: 400px;
  margin: auto;
  display: flex;
}
.listing .container .rytlist {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px 14px;
  border: 1px solid #e6e6e6;
  position: sticky;
  top: 100px;
}
.listing .container .rytlist h3 {
  font-size: 22px;
}
.listing .container .rytlist h4 {
  font-size: 16px;
  width: 100%;
  margin-bottom: 0px;
}
.listing .container .rytlist .range_slider_adv {
  margin-top: 10px;
  margin-bottom: 10px;
}
.listing .container .rytlist .range-slider .range-slider__thumb {
  background: #6e302f !important;
  width: 18px;
  height: 18px;
}
.listing .container .rytlist .range-slider .range-slider__range {
  background: #6e302f82;
}
.listing .container .rytlist input {
  border: none;
  background-color: #fff;
  padding: 0px;
  visibility: hidden;
  width: 100%;
  border-radius: 5px;
  font-size: 14px;
}
.listing .container .rytlist input.prin {
  visibility: visible;
  text-align: center;
}
.listing .container .rytlist select {
  border: 1px solid #e6e6e6;
  background-color: #fff;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  font-size: 14px;
}
.listing .container .rytlist .list_flt {
  padding: 5px 0;
  display: flex;
  flex-wrap: wrap;
}
.listing .container .rytlist .filter {
  padding: 5px 0;
}
.listing .container .rytlist .filter input {
  flex: 1;
}
.listing .container .rytlist button {
  width: 100%;
  margin-top: 10px;
}

@media (max-width: 767px) {
  .listing .container {
    padding: 20px 20px 30px;
  }
  .listing .container .lft_cards {
    justify-content: center;
  }
}
@media (max-width: 550px) {
  .listing .container .lft_cards .rytlist h3 {
    font-size: 18px;
  }
  .listing .container .lft_cards .rytlist h4 {
    font-size: 15px;
  }
  .listing .container .lft_cards .rytlist .list_flt {
    padding: 6px 0;
  }
  .listing .container .lft_cards .rytlist .filter {
    padding: 6px 0;
  }
}
.choose {
  background-color: #f9f9f9;
  padding: 60px 0;
  margin: 0px 0 50px;
}
.choose .choose_cnt h2 {
  font-weight: 600;
  font-size: 1.75em;
}
.choose .choose_cnt h3 {
  font-size: 1.375em;
}
.choose .choose_cnt p {
  color: #707070;
  font-size: 1em;
  line-height: 20px;
  text-align: justify;
}
.choose .choose_cnt .conter {
  margin: 40px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.choose .choose_cnt .conter i {
  font-size: 30px;
  color: #6e302f;
}
.choose .choose_cnt .conter h3 {
  margin-bottom: 0;
  font-size: 22px;
}
.choose .rytc {
  gap: 20px;
}
.choose .rytc .rytcnt {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  width: calc(50% - 10px);
  background-color: #fff;
  transition: 0.3s all ease;
}
.choose .rytc .rytcnt:hover {
  box-shadow: 0 0 25px -8px #6e302f;
  transform: scale(1.01);
  transition: 0.3s all ease;
}
.choose .rytc .rytcnt.bg_primary {
  color: #fff;
  background-color: #6e302f;
}
.choose .rytc .rytcnt.bg_primary i {
  color: #fff;
}
.choose .rytc .rytcnt.bg_primary p {
  color: #f5efef;
  text-align: justify;
}
.choose .rytc .rytcnt.bg_primary:hover {
  box-shadow: 0 0 10px 0 0 6px rgba(0, 0, 0, 0.12) !important;
  transition: 0.3s all ease;
}
.choose .rytc .rytcnt p {
  color: #707070;
  font-weight: 300;
  letter-spacing: 0.3px;
  text-align: justify;
}
.choose .rytc .rytcnt i {
  font-size: 36px;
  color: #6e302f;
}
.choose .rytc .rytcnt h4 {
  font-size: 1.375em;
  margin: 10px 0;
}

@media (max-width: 550px) {
  .choose {
    padding: 20px 0 40px;
  }
  .choose .choose_cnt .d-flex {
    justify-content: center !important;
  }
  .choose .choose_cnt .conter h3 {
    font-size: 19px;
  }
  .choose .rytc .rytcnt {
    width: 100%;
    box-shadow: none;
    color: #fff;
    background-color: #6e302f;
  }
  .choose .rytc .rytcnt i {
    color: #fff;
  }
  .choose .rytc .rytcnt p {
    color: #f5efef;
  }
}
.about .abt_main {
  padding: 40px 0 0;
}
.about .abt_main .serv {
  padding: 0 12px 50px;
}
.about .abt_main .serv .abt-cnt-main > img {
  float: left;
  margin: 0 20px 20px 0;
}
.about .abt_main .h1 {
  font-weight: 600;
}
.about .abt_main p {
  font-size: 14px;
  margin-bottom: 15px;
  text-align: justify;
  color: #707070;
}
.about .abt_main a {
  color: #6e302f;
  text-decoration: none;
  transition: 0.25s all ease;
}
.about .abt_main a:hover {
  color: #6e302f;
  transition: 0.25s all ease;
}

.contact .lft_cnt {
  padding: 60px 0;
}
.contact .lft_cnt p {
  font-size: 14px;
}
.contact .lft_cnt .lcs {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 15px;
}
.contact .lft_cnt .lcs span {
  background-color: #6e302f;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 40px;
  height: 40px;
  font-size: 18px;
}
.contact .lft_cnt .lcs a {
  color: #6e302f;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
}
.contact .cnt_frm {
  background-color: #f9f9f9;
  padding: 60px;
  height: 100%;
}
.contact .cnt_frm button {
  width: 100%;
}

@media (max-width: 991px) {
  .contact .cnt_frm {
    padding: 60px 30px;
  }
}
@media (max-width: 550px) {
  .contact .cnt_frm {
    padding: 60px 15px;
  }
}
.nws_box {
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  color: #262c2e;
  text-decoration: none;
  display: block;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  transition: 0.3s all ease;
}
.nws_box:hover {
  transition: 0.3s all ease;
  transform: translateY(-5px) scale(1.01);
}
.nws_box img {
  border-radius: 10px 10px 0 0;
  width: 100%;
  height: 240px;
}
.nws_box .nws_cnt {
  padding: 20px;
  background-color: #f9f9f9;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 15px;
  border-radius: 0 0 10px 10px;
}
.nws_box .nws_cnt h2 {
  font-size: 1.5em;
  margin-bottom: 0px;
}
.nws_box .nws_cnt p {
  font-size: 14px;
  color: #707070;
}
.nws_box .nws_cnt .dte {
  font-size: 12px;
}

@media (max-width: 550px) {
  .nws_box .nws_cnt h2 {
    font-size: 1.25em;
  }
  .nws_box img {
    height: 200px;
  }
}
.news .nws {
  padding: 50px 20px 20px;
}

.careers .jobs_accordian {
  background: #fff;
  padding: 80px;
  border-radius: 30px;
  box-shadow: 2px 2px 54px rgba(0, 0, 0, 0.12);
}
.careers .jobs_accordian .accordion .accordion-item {
  border: none;
  border-bottom: 1px solid #ddd;
  margin-bottom: 26px;
}
.careers .jobs_accordian .accordion .accordion-item .accordion-header button {
  box-shadow: none;
  font-size: 0.625em;
  letter-spacing: 1px;
  color: #000;
  font-weight: 400;
  margin-bottom: 12px;
}
.careers .jobs_accordian .accordion .accordion-item .accordion-header button i {
  font-size: 1.125em;
  color: #6e302f;
  margin-right: 20px;
}
.careers .jobs_accordian .accordion .accordion-item .accordion-header button .minus-img {
  display: none;
}
.careers .jobs_accordian .accordion .accordion-item .accordion-header button .add-img {
  display: block;
}
.careers .jobs_accordian .accordion .accordion-item .accordion-header button:focus {
  box-shadow: none;
}
.careers .jobs_accordian .accordion .accordion-item .accordion-header button:not(.collapsed) {
  background: transparent;
  font-weight: 600;
}
.careers .jobs_accordian .accordion .accordion-item .accordion-header button:not(.collapsed) .minus-img {
  display: block;
}
.careers .jobs_accordian .accordion .accordion-item .accordion-header button:not(.collapsed) .add-img {
  display: none;
}
.careers .jobs_accordian .accordion .accordion-item .accordion-header button:after {
  display: none;
}
.careers .jobs_accordian .accordion .accordion-item .accordion-body {
  margin-left: 60px;
  letter-spacing: 0.5px;
  font-size: 1.25em;
  font-weight: 400;
  position: relative;
  transition: all 0.5s ease-in-out;
  margin-bottom: 10px;
  line-height: 28px;
}
.careers .jobs_accordian .accordion .accordion-item .accordion-body:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 70%;
  background: #6e302f;
  left: 0;
  top: 0;
  margin: auto;
  bottom: 0;
}
.careers .jobs_accordian .accordion .accordion-item .accordion-body .job_list {
  margin: 20px 0;
  padding-left: 25px;
}
.careers .jobs_accordian .accordion .accordion-item .accordion-body .job_list li {
  list-style: circle;
  border-bottom: none;
  margin-bottom: 0px;
  letter-spacing: 0.3px;
}
@media (max-width: 768px) {
  .careers .jobs_accordian {
    padding: 20px;
  }
}
.careers .jobs_accordian ul {
  margin-top: 38px;
}
@media (max-width: 768px) {
  .careers .jobs_accordian ul {
    padding: 0;
  }
}
.careers .jobs_accordian ul li:not(:last-child) {
  border-bottom: 1px solid #dddddd;
  margin-bottom: 26px;
}
.careers .jobs_accordian ul li .ques_box:not(:last-child) {
  margin-bottom: 10px;
}
.careers .jobs_accordian ul li .icon_box {
  margin-right: 20px;
}
.careers .jobs_accordian ul li .icon_box i {
  cursor: pointer;
}
.careers .jobs_accordian ul li .ques {
  font-size: 1.375em;
  letter-spacing: 1px;
  color: #000;
  font-weight: 400;
  margin-bottom: 12px;
  cursor: pointer;
}
.careers .jobs_accordian ul li .ans {
  letter-spacing: 0.5px;
  font-size: 1.25em;
  font-weight: 400;
  position: relative;
  transition: all 0.5s ease-in-out;
  margin-left: 15px;
  opacity: 0;
  height: 0px;
  margin-bottom: 10px;
  opacity: 0;
  visibility: hidden;
  line-height: 28px;
  height: 0px;
}
.careers .jobs_accordian ul li .ans:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 70%;
  background: #6345f6;
  left: -15px;
  top: 0;
  margin: auto;
  bottom: 0;
}
.careers .jobs_accordian ul li.active .ques {
  font-weight: 600;
}
.careers .jobs_accordian ul li.active .ans {
  height: fit-content;
  opacity: 1;
  visibility: visible;
}

.nws_box {
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  color: #262c2e;
  text-decoration: none;
  display: block;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  transition: 0.3s all ease;
}
.nws_box:hover {
  transition: 0.3s all ease;
  transform: translateY(-5px) scale(1.01);
}
.nws_box img {
  border-radius: 10px 10px 0 0;
  width: 100%;
  height: 240px;
}
.nws_box .nws_cnt {
  padding: 20px;
  background-color: #f9f9f9;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 15px;
  border-radius: 0 0 10px 10px;
}
.nws_box .nws_cnt h2 {
  font-size: 1.5em;
  margin-bottom: 0px;
}
.nws_box .nws_cnt p {
  font-size: 14px;
  color: #707070;
}
.nws_box .nws_cnt .dte {
  font-size: 12px;
}

@media (max-width: 550px) {
  .nws_box .nws_cnt h2 {
    font-size: 1.25em;
  }
  .nws_box img {
    height: 200px;
  }
}
.news_inner {
  padding: 120px 0 40px;
}
.news_inner .nws_main {
  background-color: #f9f9f9;
  padding: 80px;
}
.news_inner .nws_main h2 {
  font-weight: 600;
}
.news_inner .nws_main img {
  width: 100%;
  max-height: 460px;
  object-fit: contain;
  margin-bottom: 20px;
}
.news_inner .nws_main .dte {
  margin-bottom: 10px;
  display: block;
}
.news_inner .nws_main h3 {
  margin-top: 15px;
}
.news_inner .nws_main p {
  font-size: 1.25em;
  margin-bottom: 10px;
  color: #707070;
}
.news_inner .sld_news {
  padding: 60px 0 20px;
}
.news_inner .sld_news .sld .nws_box:hover {
  transition: 0.3s all ease;
  transform: translateY(-5px) scale(1.01);
}
.news_inner .sld_news .sld .slick-slide {
  padding: 10px;
}
.news_inner .sld_news .sld .slick-next {
  right: -20px;
  color: #000;
  border-radius: 4px 0 0 4px;
  padding: 10px 5px;
  height: 40px;
  width: 30px;
  transition: 0.3s all ease;
}
.news_inner .sld_news .sld .slick-next:before {
  content: "\f105";
  font-family: "Font Awesome 5 Pro";
  color: #000;
  font-size: 28px;
}
.news_inner .sld_news .sld .slick-next:hover::before {
  transition: 0.3s all ease;
  color: #6e302f;
}
.news_inner .sld_news .sld .slick-prev {
  left: -20px;
  border-radius: 0 4px 4px 0;
  padding: 10px 5px;
  z-index: 9;
  color: #000;
  height: 40px;
  width: 30px;
  transition: 0.3s all ease;
}
.news_inner .sld_news .sld .slick-prev:before {
  content: "\f104";
  font-family: "Font Awesome 5 Pro";
  color: #000;
  font-size: 28px;
}
.news_inner .sld_news .sld .slick-prev:hover::before {
  color: #6e302f;
  transition: 0.3s all ease;
}

@media (max-width: 991px) {
  .news_inner .nws_main {
    padding: 40px;
  }
}
@media (max-width: 767px) {
  .news_inner .nws_main {
    padding: 30px;
  }
}
@media (max-width: 550px) {
  .news_inner .nws_main {
    padding: 20px;
  }
  .news_inner .nws_main h2 {
    font-size: 20px;
  }
  .news_inner .nws_main h3 {
    font-size: 18px;
  }
  .news_inner .nws_main p {
    font-size: 14px;
  }
  .news_inner .sld_news {
    padding: 40px 0 20px;
  }
}
.developer_detail {
  padding: 40px 0;
}
.developer_detail p {
  font-size: 14px;
  margin-bottom: 15px;
  text-align: justify;
  color: #707070;
}
.developer_detail .developer_img_bog {
  border-radius: 10px;
  padding: 16px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  min-height: 180px;
}
.developer_detail .developer_img_bog > img {
  object-fit: scale-down;
}

.dvlpr_cont {
  padding: 50px 16px;
}
.dvlpr_cont .property_card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  height: 100%;
  display: block;
  width: 100%;
  color: #262c2e;
  text-decoration: none;
  transition: 0.3s all ease;
}
.dvlpr_cont .property_card img {
  width: 100%;
  border-bottom: 1px solid #f7f7f7;
  padding: 10px;
  height: 140px;
  object-fit: scale-down;
}
.dvlpr_cont .property_card h4 {
  padding: 16px;
}
.dvlpr_cont .property_card:hover {
  color: #6e302f;
  transform: scale(1.05);
  transition: 0.3s all ease;
  box-shadow: #6e302f54 0px 1px 8px;
}

.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f7f7f7;
}

.not-found-content {
  text-align: center;
}

.not-found-heading {
  font-size: 120px;
  color: #ff6f61;
  margin: 0;
}

.not-found-text {
  font-size: 24px;
  color: #333;
  margin-bottom: 40px;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

