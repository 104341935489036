.team {
    padding-bottom: 60px;

    .team_box {
        padding: 15px;
        border-radius: 10px;
        background-color: #f9f9f9;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid $light-grey;
        transition: .4s all ease;

        img {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 220px;
            border-radius: 10px;
            margin-bottom: 20px;
        }

        .tm-cnt {
            display: flex;
            flex-direction: column;
            gap: 8px;
            justify-content: center;
            align-items: center;
            text-align: center;

            h4 {
                font-size: em(19);
                font-weight: 600;
                text-wrap: nowrap;
            }

            h5 {
                font-size: em(17);
            }

            span {
                font-size: em(14);
                margin-bottom: 20px;
            }
        }

        .scbx {
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
            justify-content: center;

            a {
                color: $theme-primary;
                text-decoration: none;
                font-size: 22px;
                transition: 0.3s all ease;

                &:hover {
                    transform: scale(1.12);
                    transition: 0.3s all ease;
                }
            }
        }

        &:hover {
            transition: .4s all ease;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
            transform: scale(1.01);
        }
    }
}