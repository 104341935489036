.top_navbar {
    position: absolute;
    top: 0;
    z-index: 99;
    width: 100%;
    background: #fff;
    transition: 0.3s all ease;
    height: 75px;

    nav {
        position: absolute;
        top: 0;
        z-index: 99;
        width: 100%;
        background: #fff;
        transition: 0.3s all ease;
        height: 75px;
    }

    nav.bg-fff {
        position: fixed;
        background-color: #fff;
        box-shadow: 0 0 9px rgba(0, 0, 0, 0.18);
        transition: 0.3s all ease;
    }

    nav .wrapper {
        position: relative;
        height: 100%;
        line-height: 48px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
    }

    .logo {
        width: 90px;

        img {
            max-width: 85px;
        }
    }

    .wrapper .logo a {
        color: #fff;
        font-size: 30px;
        font-weight: 600;
        text-decoration: none;
    }

    .wrapper .nav-links {
        display: inline-flex;
        margin-bottom: 0px;
        /* margin-left: 10%; */
        padding-left: 0px;
    }

    .numb_nav {
        text-align: right;
        margin-left: 20px;
    }

    .down-an {
        vertical-align: middle;
    }

    .nav-ic {
        color: #cc9562;
    }

    .nav-span {
        display: block;
    }

    .nav-links li {
        list-style: none;
    }

    .nav-links li a i {
        vertical-align: text-top;
    }

    .nav-links li a,
    .nav-links li button {
        color: $text-color;
        text-decoration: none;
        font-size: 16px;
        font-weight: 600;
        padding: 25px 15px;
        transition: all 0.3s ease;
        border-bottom: 3px solid transparent;
    }

    .nav-links li a i {
        vertical-align: middle;
    }

    .nav-links li a:hover,
    .nav-links li button:hover {
        color: $theme-primary !important;
        border-bottom: 2px solid $theme-primary !important;
    }

    .nav-links .mobile-item {
        display: none;
    }

    .nav-links .drop-menu {
        position: absolute;
        background-color: #fff;
        min-width: 200px;
        max-width: 380px;
        width: auto;
        line-height: 35px;
        top: 100px;
        opacity: 0;
        padding: 0px 0px 0px 0;
        visibility: hidden;
        border-width: 1px 1px 0 1px;
        border-color: #f8f8f9;
        border-style: solid;
    }

    .nvbxn {
        width: 200px;
    }

    .imgbxn {
        width: 110px;
        line-height: 18px !important;
    }

    .imgnv {
        padding: 0px !important;
        font-weight: 600 !important;
        font-size: 12px !important;
        color: #fff !important;
    }

    .imgnv:hover {
        color: #e36f1e !important;
    }

    .lstdrp {
        right: 0;
    }

    .dntebtn {
        background-color: $theme-primary;
        border: 2px solid $theme-primary !important;
        color: #fff !important;
        font-weight: 400 !important;
        padding: 10px 20px !important;
    }

    .dntebtn:hover {
        background-color: #fff !important;
        color: $theme-primary !important;
    }

    .dntebtn2 {
        background-color: #fff;
        border: 2px solid $theme-primary !important;
        color: $theme-primary !important;
        font-weight: 400 !important;
        padding: 10px 20px !important;
        margin: 0 15px;
    }

    .dntebtn2:hover {
        background-color: $theme-primary !important;
        color: #fff !important;
    }

    .nav-links li:hover .drop-menu,
    .nav-links li:hover .mega-box {
        transition: all 0.3s ease;
        top: 76px;
        opacity: 1;
        visibility: visible;
    }

    .drop-menu li a {
        width: 100%;
        display: block;
        padding: 1px 20px;
        font-weight: 400 !important;
        font-size: 14px !important;
        color: $text-color !important;
        background-color: #fff;
        text-align: left;
        border-bottom: 1px solid #f8f8f9;
    }

    .drop-menu li a:hover {
        text-decoration: none;
        border-bottom: 1px solid #f8f8f9 !important;
        background-color: $light-grey;
    }

    .megal {
        width: 65% !important;
        left: 100px;
    }

    .wrapper .btn.close-btn,
    .wrapper .btn.menu-btn {
        color: #fff;
        font-size: 20px;
        cursor: pointer;
        display: none;
    }

    .wrapper .btn.close-btn {
        color: $theme-primary;
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .mygr {
        color: #38b87c;
        font-size: 16px;
        font-weight: 500;
    }

    @media screen and (max-width: 992px) {
        .wrapper .btn {
            display: block;
            color: #20ad9e;
        }

        .corner-nav {
            width: 50px;
            height: 50px;
            bottom: -23%;
            left: 9%;
        }

        .menu-btn {
            margin-left: 0%;
            color: $theme-primary !important;
            font-size: 28px !important;
        }

        nav .wrapper {
            padding: 0px 20px 0px 20px;
            justify-content: space-between;
        }

        .numb_nav {
            width: 50%;
            text-align: left;
            margin-top: 20px;
        }

        .wrapper .nav-links {
            position: fixed;
            height: 100vh;
            width: 100%;
            max-width: 320px;
            top: 0%;
            left: -120%;
            background: #fff;
            display: block;
            margin-left: 0%;
            padding: 50px 0px 0px;
            line-height: 50px;
            overflow-y: auto;
            overflow-x: hidden;
            box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.18);
            transition: all 0.3s ease;
            color: #fff;
        }

        .bordbt {
            border-bottom: 2px solid #353535;
        }

        /* custom scroll bar */
        ::-webkit-scrollbar {
            width: 10px;
        }

        ::-webkit-scrollbar-track {
            background: #242526;
        }

        ::-webkit-scrollbar-thumb {
            background: #3a3b3c;
        }

        #menu-btn:checked ~ .nav-links {
            left: 0%;
        }

        #menu-btn:checked ~ .btn.menu-btn {
            display: none;
        }

        #close-btn:checked ~ .btn.menu-btn {
            display: block;
        }

        .nav-links li a {
            padding: 0px 20px;
            display: block;
            font-size: 14px;
            border-radius: 0px;
            font-weight: 400;
            transition: 0.3s all ease;
            border-bottom: 1px solid #f8f8f9;
        }

        .let_anc {
            padding-left: 0px !important;
            margin: 20px auto 0px 28%;
        }

        .dntebtn {
            padding: 5px 20px !important;
        }

        .nav-links .drop-menu {
            position: relative;
            opacity: 1;
            top: 0px !important;
            left: 0%;
            visibility: visible;
            padding-left: 0px;
            width: 100%;
            max-height: 0px;
            overflow: hidden;
            box-shadow: none;
            transition: all 0.3s ease;
            background-color: transparent;
            border-radius: 0px;
            border-left: 0px;
            padding: 0px;
            border-right: 0px;
            background-color: #fff;
        }

        #showDrop:checked ~ .drop-menu,
        #showMega:checked ~ .mega-box {
            max-height: 100%;
            transition: 0.3s all ease;
            left: 0px;
            top: 0px;
        }

        #showDrop2:checked ~ .drop-menu {
            max-height: 100%;
            transition: 0.3s all ease;
            left: 0px;
            top: 0px;
        }

        #showDrop3:checked ~ .drop-menu {
            max-height: 100%;
            transition: 0.3s all ease;
            left: 0px;
            top: 0px;
        }

        #showDrop4:checked ~ .drop-menu {
            max-height: 100%;
            transition: 0.3s all ease;
            left: 0px;
            top: 0px;
        }

        #showDrop5:checked ~ .drop-menu {
            max-height: 100%;
            transition: 0.3s all ease;
            left: 0px;
            top: 0px;
        }

        #showDropcom:checked ~ .drop-menu,
        #showMegainvest:checked ~ .mega-box {
            max-height: 100%;
        }

        .nav-links .desktop-item {
            display: none;
        }

        .nav-links .mobile-item i {
            margin-left: auto;
        }

        .nav-links .mobile-item {
            display: flex;
            align-items: center;
            color: #102442;
            font-size: 14px;
            font-weight: 400;
            padding: 0px 10px;
            padding-left: 20px;
            cursor: pointer;
            transition: all 0.3s ease;
            border-bottom: 1px solid #f8f8f9;
        }

        /* .nav-links .mobile-item:hover{
      background: #3A3B3C;
    } */
        .drop-menu li {
            margin: 0;
        }

        .drop-menu li a {
            font-size: 14px !important;
            color: #fff;
            border-bottom: 1px solid #f8f8f9 !important;
            padding: 6px 20px;
        }

        .drop-menu li a:hover {
            background-color: #fff;
            text-decoration: none;
            color: #0066b1;
        }

        .drop_a,
        .drop_b {
            border-radius: 0px;
        }

        .drop-corner {
            display: none;
        }

        .megal {
            width: 100% !important;
        }

        .dntebtn,
        .dntebtn2 {
            width: fit-content !important;
            padding: 0 20px !important;
            margin: 10px 15px 0;
        }
    }

    nav input {
        display: none;
    }

    .body-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        text-align: center;
        padding: 0 30px;
    }

    .body-text div {
        font-size: 45px;
        font-weight: 600;
    }

    @media (max-width: 550px) {
        .logo {
            img {
                max-width: 64px;
            }
        }
    }
    &.variant_2 {
        @media screen and (min-width: 992px) {
            background: #f3f3f329;
            nav {
                background: #f3f3f329;
                backdrop-filter: blur(30px) brightness(124%);
                .nav-links li a {
                    color: #fff;
                }
            }
        }
    }
}
