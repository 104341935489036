@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200&display=swap");

* {
    margin: 0;
    padding: 0;
    outline: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
}

html {
    font-size: 14px;
    scroll-behavior: smooth;
}

body {
    font-family: "Poppins", sans-serif;
    font-size: 0.88rem;
    color: $text-color;
    letter-spacing: 0.25px;
    background-color: $body-bg;
}

img {
    max-width: 100%;
}

// typography
p {
    margin-bottom: 0;
}

.container {
    // width: 100%;
    // max-width: 1280px;
    // margin: auto;
    // padding: 0 20px;
}

.btn_yl {
    width: fit-content;
    border: none;
    outline: none;
    padding: 10px 25px;
    position: relative;
    background-color: $theme-primary;
    color: #fff;
    font-size: 16px;
    z-index: 1;
    text-decoration: none;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-color: $theme-secondary;
        width: 100%;
        height: 100%;
        max-width: 0;
        width: 100%;
        z-index: -1;
        transition: 0.3s all ease;
    }

    &:not(.remove_overlay) {
        &:hover {
            &::before {
                max-width: 100%;
                transition: 0.3s all ease;
            }
        }
    }
}

.btn_bg_none {
    border: 2px solid $theme-primary;
    outline: none;
    padding: 5px 10px;
    position: relative;
    transition: $transition;
    background-color: transparent;
    color: $theme-primary;
    font-size: 12px;
    z-index: 1;
    text-decoration: none;
    font-weight: 600;

    &:hover {
        background-color: $theme-primary;
        color: #fff;
    }
}

.primary_color {
    color: $theme-primary;
}

.secondary_color {
    color: $theme-secondary;
}

.primary_badge {
    background-color: #6e302f;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    font-size: em(14);
}

.sec_badge {
    background-color: #43766c;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    font-size: em(14);
}

.ter_badge {
    background-color: #ffb534;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    font-size: em(14);
}
.info_badge {
    background-color: #0bbbff;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    font-size: em(14);
}

.bg_primary {
    background-color: $theme-primary;
}

.light_text {
    color: $light-text;
}

.heading_line {
    flex: 1;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        width: calc(100% - 30px);
        height: 1px;
        background: $light-grey;
        margin: auto;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    }
}

.hover_primary_color {
    &:hover {
        color: $theme-primary;
    }
}

.fs-14 {
    font-size: em(14);
}

.fw-4 {
    font-weight: 400;
}

.border_card {
    border-radius: 6px;
    border: 1px solid $light-grey;
}

.section_heading {
    color: $theme-primary;
    display: flex;
    margin-bottom: 40px;
}

input {
    border: 1px solid #e6e6e6;
    background-color: #fff;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    margin-top: 10px;
    font-size: 14px;
}

textarea {
    height: 150px;
    border: 1px solid #e6e6e6;
    background-color: #fff;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    margin-top: 10px;
    font-size: 14px;
}

.custom_form_group {
    width: 33%;

    label {
        color: $theme-primary;
        letter-spacing: 0.6px;
        text-align: left;
        width: 100%;
        margin-bottom: 0;
        font-weight: 400;
        font-size: 12px;
    }
}

.line-3-ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
}

.line-2-ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}
