.testimonials {
    .slick-slide {
        padding: 0 10px;
    }

    .slick-list {
        padding: 60px 0;
    }

    .slick-prev:before,
    .slick-next:before {
        color: $theme-primary !important;
    }

    .slick-next {
        right: -10px !important;
    }

    .slick-prev {
        left: -10px !important;
        z-index: 999;
    }

    .rvs {
        background-color: #f9f9f9;
        border-radius: 10px;
        padding: 60px 30px 30px 30px;
        position: relative;
        max-width: 500px;
        margin: 40px auto 0;
        min-height: 200px;

        .rvimg {
            position: absolute;
            top: -90px;
            left: 0;
            right: 0;
            margin: auto;
            width: 140px;
            height: 140px;
            border-radius: 100%;
        }

        .rvp {
            text-align: center;
            font-style: italic;
            font-size: 14px;
        }

        .rvp2 {
            text-align: center;
            font-style: italic;
            font-size: 14px;
            font-weight: 600;
            margin-top: 15px;
        }
    }


    // .testis {
    //     backdrop-filter: blur(30px) brightness(124%);
    //     margin: 0 10px;
    //     text-align: center;
    //     position: relative;
    //     padding: 80px 30px 30px;

    //     &::before {
    //         content: "";
    //         position: absolute;
    //         width: calc(100% - 20px);
    //         height: calc(100% - 20px);
    //         top: 10px;
    //         left: 10px;
    //         border: 4px solid $theme-primary;
    //     }

    //     .user_icon {
    //         width: 120px;
    //         height: 120px;
    //         border-radius: 100%;
    //         position: absolute;
    //         top: -60px;
    //         left: 0;
    //         right: 0;
    //         margin: auto;
    //         z-index: 1;
    //         font-size: 40px;
    //         backdrop-filter: blur(30px) brightness(124%);
    //         background-color: rgba(255, 255, 255, .8);
    //         color: $theme-primary;
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //     }

    //     span {
    //         font-size: 52px;
    //         color: $theme-primary;
    //     }

    //     .desc {
    //         font-size: 14px;
    //         max-width: 280px;
    //         margin: auto;
    //     }
    // }
}

@media(max-width:550px) {
    .testimonials {
        .testis {
            padding: 40px 30px 30px;

            .user_icon {
                width: 90px;
                height: 90px;
                top: -45px;
                font-size: 30px;
            }

            span {
                font-size: 40px;
            }
        }

        .rvs {
            padding: 60px 10px 20px;

            .rvimg {
                width: 100px;
                height: 100px;
                top: -50px;
            }
        }
    }
}