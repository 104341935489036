.news {
    padding-bottom: 60px;

    .d-flex {
        flex-wrap: wrap;
        gap: 25px;

        .news_box {
            // min-width: 500px;
            // flex: 1;
            display: flex;
            gap: 0;
            color: $text-color;
            text-decoration: none;
            border-radius: 10px;
            box-shadow: $box_shadow_1;
            border: 1px solid $light-grey;
            overflow: hidden;
            .img_box {
                height: 160px;
                min-width: 140px;
                max-width: 140px;
                img {
                    object-fit: contain;
                    object-position: center;
                    width: 100%;
                    height: 100%;
                }
            }

            .news_cont {
                padding: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                h3 {
                    font-size: 18px;
                    font-weight: 600;
                    color: $text-color;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                    height: 21px;
                    overflow: hidden;
                    transition: $transition;
                }

                .desc {
                    padding: 12px 12px 12px 0;
                    margin-bottom: 10px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    color: $light-text;
                    text-overflow: ellipsis;
                    font-size: em(16);
                    height: 46px;
                    overflow: hidden;
                }

                .date {
                    font-size: em(14);
                    color: $light-text;
                }
            }

            &:hover {
                .news_cont {
                    h3 {
                        color: $theme-primary;
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .news {
        .d-flex {
            .news_box {
                // min-width: 320px;
            }
        }
    }
}

@media (max-width: 550px) {
    .news {
        .d-flex {
            .news_box {
                // min-width: 280px;

                img {
                    width: 90px;
                }

                .news_cont {
                    h3 {
                        font-size: 16px;
                    }

                    .desc {
                        padding: 0;
                        height: 46px;
                        margin-bottom: 0px;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
