.premium_design {
    background-color: #edf9f9;
    padding: 40px 20px 60px 60px;
    border-radius: 12px;
    margin: 60px 0;

    .d-flex {
        max-width: 1100px;
        margin: auto;

        .yl_bx {
            position: relative;
            .img_wrapper{
                border-radius: 6px;
                overflow: hidden;
            }

            &::before {
                content: "";
                position: absolute;
                left: -15px;
                top: 15px;
                background-color: $theme-primary;
                width: 100%;
                height: 100%;
                border-radius: 6px;

            }

            img {
                position: relative;
                z-index: 1;
            }
        }

        .cnt_bx {
            h2 {
                font-size: 30px;
                font-weight: 600;
            }

            p {
                font-size: em(16);
                margin-bottom: 5px;
                color: $light-text;
            }

            button {
                padding: 12px 25px;
            }
        }
    }
}

@media (max-width: 991px) {
    .premium_design {
        .d-flex {
            .cnt_bx {
                h2 {
                    font-size: 26px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .premium_design {
        .d-flex {
            .cnt_bx {
                margin-top: 20px;
            }
        }
    }
}

@media (max-width: 550px) {
    .premium_design {
        padding: 20px 20px 20px 30px;
        margin: 30px 0;

        .d-flex {
            .cnt_bx {
                h2 {
                    font-size: 20px;
                }
            }
        }
    }
}