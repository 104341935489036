$theme-primary: #6e302f;
$theme-secondary: #e39392;
$border-radius: 10px;
$body-bg: #fff;
$text-color: #262c2e;
$box-shadow: 0 0 60px rgb(0 0 0 / 12%);
$box_shadow_1: 0 0 6px rgb(0 0 0 / 12%);
$light-grey: #e6e6e6;
$transition: 0.3s all ease;
$light-text: #707070;
$color-dark: #384347;
