@import "../components/list_banner";

.listing {

    .container {
        padding: 40px 20px;

        .lft_cards {
            gap: 20px;

            .card_box {
                min-width: 260px;
            }

            .nodatafound {
                display: flex;
                flex-direction: column;
                gap: 18px;
                justify-content: center;
                align-items: center;
                text-align: center;

                >img {
                    max-width: 400px;
                    margin: auto;
                    display: flex;
                }
            }
        }

        .rytlist {
            background-color: #f9f9f9;
            border-radius: 10px;
            padding: 20px 14px;
            border: 1px solid #e6e6e6;
            position: sticky;
            top: 100px;

            h3 {
                font-size: 22px;
            }

            h4 {
                font-size: 16px;
                width: 100%;
                margin-bottom: 0px;
            }

            .range_slider_adv {
                margin-top: 10px;
                margin-bottom: 10px;
            }

            .range-slider .range-slider__thumb {
                background: $theme-primary !important;
                width: 18px;
                height: 18px;
            }

            .range-slider .range-slider__range {
                background: #6e302f82;
            }

            input {
                border: none;
                background-color: #fff;
                padding: 0px;
                visibility: hidden;
                width: 100%;
                border-radius: 5px;
                font-size: 14px;

                &.prin {
                    visibility: visible;
                    text-align: center;
                }
            }

            select {
                border: 1px solid #e6e6e6;
                background-color: #fff;
                padding: 10px;
                width: 100%;
                border-radius: 5px;
                font-size: 14px;
            }

            .list_flt {
                padding: 5px 0;
                display: flex;
                flex-wrap: wrap;
                // gap: 10px;
            }

            .filter {
                padding: 5px 0;

                input {
                    flex: 1;
                }
            }

            button {
                width: 100%;
                margin-top: 10px;
            }
        }
    }
}

@media (max-width: 767px) {
    .listing {
        .container {
            padding: 20px 20px 30px;

            .lft_cards {
                justify-content: center;
            }
        }
    }
}

@media(max-width: 550px) {
    .listing {
        .container {
            .lft_cards {
                .rytlist {
                    h3 {
                        font-size: 18px;
                    }

                    h4 {
                        font-size: 15px;
                    }

                    .list_flt {
                        padding: 6px 0;
                    }

                    .filter {
                        padding: 6px 0;
                    }
                }
            }
        }
    }
}