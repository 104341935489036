.cta {
    padding: 0 0 40px;

    .cta_main {
        background-color: #f9f9f9;
        padding: 30px;
        text-align: center;
        border-radius: 10px;

        p {
            color: $light-text;
            font-size: 14px;
            max-width: 900px;
            margin: 20px auto 0;

        }

        .consult-btn {
            cursor: pointer;
            display: block;
            text-decoration: none;
            margin: 25px auto 0;
            padding: 10px 20px;
            border: 2px solid $theme-primary;
            transition: .3s all ease;
            background-color: $theme-primary;
            color: #fff;
            width: fit-content;
            font-size: 16px;

            &:hover {
                background-color: #f9f9f9;
                color: $theme-primary;
            }
        }
    }
}

.ctamodal {
    // background: linear-gradient(45deg, #632b2b, transparent);

    .modal-body {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    }

    .cntfrm {
        padding-top: 50px;

        >img {
            position: absolute;
            top: -50px;
            width: 100px;
            height: 100px;
            border-radius: 100%;
            background: #fff;
            left: 0;
            right: 0;
            margin: auto;
            padding: 8px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
        }

        h3 {
            text-align: center;
            text-transform: uppercase;
            color: $theme-primary;
            font-weight: 600;
            display: flex;
            align-items: center;
            gap: 10px;

            &::before {
                content: "";
                height: 1px;
                flex: 1;
                background-color: $light-grey;
            }

            &::after {
                content: "";
                height: 1px;
                flex: 1;
                background-color: $light-grey;
            }
        }
    }
}