.counter {
    background-color: $theme-primary;
    padding: 50px 0;
    text-align: center;
    margin: 50px 0 0;

    .contr {
        display: flex;
        flex-direction: column;
        i {
            color: #fff;
            font-size: 48px;
            margin-bottom: 10px;
        }

        h2 {
            font-size: em(34);
            font-weight: 600;
            color: #fff;
            margin-bottom: 0;
        }

        p {
            font-size: em(16);
            font-weight: 300;
            color: #f5efef;
        }

    }
}

@media(max-width: 991px) {
    .counter {
        .contr {
            i {
                font-size: 60px;
            }

            h2 {
                font-size: em(26);
            }
        }
    }
}

@media(max-width: 550px) {
    .counter {
        padding: 30px 0;

        .contr {
            i {
                font-size: 36px;
            }

            h2 {
                font-size: em(24);
            }

            p {
                font-size: em(17);
            }
        }
    }
}