.card_box {
    max-width: 400px;
    flex: 1;
    box-shadow: $box_shadow_1;
    border-radius: 10px;
    overflow: hidden;

    .slider_box {
        position: relative;
        height: 260px;
        border-radius: 10px;
        width: 100%;

        .slid {
            position: absolute;
            top: 0;
            left: 0;
            height: 260px;
            width: 100%;

            img {
                height: 260px;
                width: 100%;
            }

            .slick-dots {
                bottom: 15px !important;
                text-align: right !important;
                padding: 0 20px;
                z-index: 1;

                li {
                    button:before {
                        color: #fff !important;
                        width: 12px !important;
                        height: 12px !important;
                        font-size: 12px !important;
                        opacity: 0.8 !important;
                    }

                    &.slick-active {
                        button:before {
                            color: $theme-primary !important;
                            opacity: 1 !important;
                        }
                    }
                }
            }

            .slick-slide {
                padding: 0 !important;
            }
        }

        .sell {
            position: absolute;
            top: 25px;
            border-radius: 0;
            border-top-right-radius: 10px;
        }

        .select_box {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            padding: 3px 10px;
            backdrop-filter: blur(30px) brightness(124%);
            border-radius: 5px 0 0 5px;
            position: absolute;
            top: 25px;
            right: 0px;

            button {
                border: none;
                outline: none;
                background-color: transparent;
                font-size: 15px;
            }
        }
    }

    .content_box {
        padding: 20px 10px;

        .tags {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            font-size: em(18);

            .card_lower {
                color: $text-color;
                text-decoration: none;
                transition: 0.3s all ease;

                &:hover {
                    transition: 0.3s all ease;
                    color: $theme-primary;
                }
            }

            span {
                margin: 0 5px;
            }
        }

        .name {
            font-size: 24px;
            font-weight: 500;
            color: $text-color;
            text-decoration: none;
            position: relative;

            &::before {
                content: "";
                height: 2px;
                width: 100%;
                max-width: 0;
                position: absolute;
                bottom: 0;
                left: 0;
                background-color: $theme-primary;
                transition: 0.3s all ease;
            }

            &:hover {
                &::before {
                    max-width: 100%;
                    transition: 0.3s all ease;
                }
            }
        }

        p {
            font-size: 12px;
            padding: 15px 0;
            margin-top: 0;
        }

        h3 {
            font-size: em(24);
            color: $theme-primary;
            font-weight: 600;

            span {
                color: $light-text;
                font-size: em(14);
                font-weight: 500;
            }
        }

        .price_box {
            border-top: 1px solid $light-grey;
            padding-top: 20px;
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            justify-content: space-between;
            align-items: center;

            .haves {
                display: flex;
                flex-wrap: wrap;
                // justify-content: center;
                gap: 10px;

                >div {
                    font-size: 14px;

                    i {
                        font-size: 16px;
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .card_box {
        max-width: 100%;
    }
}

@media (max-width: 550px) {
    .card_box {
        min-width: 280px;

        .slider_box {
            height: 200px;

            .slid {
                height: 200px;

                img {
                    height: 200px;
                }
            }
        }

        .content_box {
            .tags {
                font-size: 14px;
            }

            .name {
                font-size: 18px;
            }
        }
    }
}