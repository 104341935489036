.dvlpr_cont {
    padding: 50px 16px;

    .property_card {
        background-color: #fff;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        height: 100%;
        display: block;
        width: 100%;
        color: $text-color;
        text-decoration: none;
        transition: .3s all ease;

        img {
            width: 100%;
            border-bottom: 1px solid #f7f7f7;
            padding: 10px;
            height: 140px;
            object-fit: scale-down;
        }

        h4 {
            padding: 16px;
        }

        &:hover {
            color: $theme-primary;
            transform: scale(1.05);
            transition: .3s all ease;
            box-shadow: #6e302f54 0px 1px 8px;

        }
    }
}