.custom_image_gallery {
    .image-gallery-icon {
        &:hover {
            color: $theme-primary;
        }
    }
    .image-gallery-thumbnail.active,
    .image-gallery-thumbnail:focus,
    .image-gallery-thumbnail:hover {
        border: 2px solid $theme-primary;
    }
    .image-gallery-thumbnail .image-gallery-thumbnail-image {
        height: 100%;
    }
    .image-gallery-thumbnail .image-gallery-thumbnail-inner {
        height: 70px;
    }
}
