@import "../components/choose";

.about {
    .abt_main {
        padding: 40px 0 0;

        .serv {
            padding: 0 12px 50px;

            .abt-cnt-main {
                >img {
                    float: left;
                    margin: 0 20px 20px 0;
                }
            }
        }

        .h1 {
            font-weight: 600;
        }

        p {
            font-size: 14px;
            margin-bottom: 15px;
            text-align: justify;
            color: $light-text;
        }

        a {
            color: $theme-primary;
            text-decoration: none;
            transition: 0.25s all ease;

            &:hover {
                color: $theme-primary;
                transition: 0.25s all ease;
            }
        }
    }
}