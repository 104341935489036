.choose {
    background-color: #f9f9f9;
    padding: 60px 0;
    margin: 0px 0 50px;

    .choose_cnt {
        h2 {
            font-weight: 600;
            font-size: em(28);
        }

        h3 {
            font-size: em(22);
        }

        p {
            color: $light-text;
            font-size: em(16);
            line-height: 20px;
            text-align: justify;
        }

        .conter {
            margin: 40px 0;
            text-align: center;
            display: flex;
            flex-direction: column;
            gap: 15px;

            i {
                font-size: 30px;
                color: $theme-primary;
            }

            h3 {
                margin-bottom: 0;
                font-size: 22px;
            }
        }
    }

    .rytc {
        gap: 20px;

        .rytcnt {
            padding: 20px;
            border-radius: 10px;
            box-shadow: $box_shadow_1;
            width: calc(50% - 10px);
            background-color: #fff;
            transition: .3s all ease;

            &:hover {
                box-shadow: 0 0 25px -8px $theme-primary;
                transform: scale(1.01);
                transition: .3s all ease;
            }

            &.bg_primary {
                color: #fff;
                background-color: $theme-primary;

                i {
                    color: #fff;
                }

                p {
                    color: #f5efef;
                    text-align: justify;
                }

                &:hover {
                    box-shadow: 0 0 10px $box_shadow_1 !important;
                    transition: .3s all ease;
                }
            }

            p {
                color: $light-text;
                font-weight: 300;
                letter-spacing: 0.3px;
                text-align: justify;
            }

            i {
                font-size: 36px;
                color: $theme-primary;
            }

            h4 {
                font-size: em(22);
                margin: 10px 0;
            }
        }
    }
}

@media(max-width: 550px) {
    .choose {
        padding: 20px 0 40px;

        .choose_cnt {
            .d-flex {
                justify-content: center !important;
            }

            .conter {
                h3 {
                    font-size: 19px;
                }
            }
        }

        .rytc {
            .rytcnt {
                width: 100%;
                box-shadow: none;
                color: #fff;
                background-color: $theme-primary;

                i {
                    color: #fff;
                }

                p {
                    color: #f5efef;
                }

            }
        }
    }
}