@import "../components/newscard";


.news {
    .nws {
        padding: 50px 20px 20px;

    }
}

// @media(max-width: 550px){
//     .news{
//         .nws{
//             .nws_box{
//                 .nws_cnt{
//                     h2{
//                         font-size: em(20);
//                     }
//                 }
//             }
//         }
//     }
// }