.banner_box {
    position: relative;

    height: 100vh;
    min-height: 500px;
    width: 100%;

    .slider_box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .slick-slider {
            height: 100%;
            width: 100%;
            max-width: 100%;
            .slick-list {
                height: 100%;
            }
        }

        img {
            width: 100%;
            min-height: 100vh;
            /* min-height: 500px; */
            object-fit: cover;
            object-position: center;
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.75);
            z-index: 1;
        }

        .slick-dots {
            bottom: 15px !important;
            z-index: 999;

            li {
                button:before {
                    color: #fff !important;
                    width: 20px !important;
                    height: 20px !important;
                    font-size: 14px !important;
                    opacity: 0.8 !important;
                }

                &.slick-active {
                    button:before {
                        color: $theme-primary !important;
                        opacity: 1 !important;
                    }
                }
            }
        }
    }

    .bnr_cnt {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        max-width: 1120px;
        margin: auto;
        padding: 0 20px;
        color: #fff;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;

        h1 {
            font-size: 52px;
            max-width: 600px;
            width: 100%;
            margin: 75px 0 25px;
            color: #fff;
        }

        .tabs {
            display: flex;
            flex-wrap: wrap;
            gap: 2px;
            padding-left: 20px;

            button {
                backdrop-filter: blur(30px) brightness(124%);
                padding: 5px 15px;
                font-size: 14px;
                border-radius: 5px 5px 0 0;
                border: 0;
                outline: none;
                background-color: transparent;
                color: #fff;

                &.active {
                    background-color: #fff;
                    color: $text-color;
                }
            }
        }

        .srch_bx {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            padding: 35px;
            backdrop-filter: blur(30px) brightness(124%);
            border-radius: 8px;

            select {
                width: calc(25% - 10px);
                min-width: 240px;
                flex: 1;
                padding: 10px 15px;
                border: 1px solid #a4a4a4;
                font-size: 16px;
                cursor: pointer;
            }
        }
    }
}

@media (max-width: 991px) {
    .banner_box {
        .bnr_cnt {
            h1 {
                font-size: 42px;
            }
        }
    }
}

@media (max-width: 767px) {
    .banner_box {
        .bnr_cnt {
            h1 {
                font-size: 32px;
            }
        }
    }
}

@media (max-width: 550px) {
    .banner_box {
        .bnr_cnt {
            top: 40px;

            h1 {
                font-size: 26px;
            }

            .srch_bx {
                padding: 15px;
                gap: 10px;

                select,
                button {
                    font-size: 14px;
                }
            }
        }
    }
}

.search_bar_container {
    background: #fff;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 5px;
    min-height: 80px;
    padding: 10px;
    margin: 20px;
    .search_bar_wrapper {
        width: 100%;
        gap: 7px;
        // width: calc(100% - 77px);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .custom_form_group {
        width: 33%;
        label {
            color: $theme-primary;
            letter-spacing: 0.6px;
            text-align: left;
            width: 100%;
            margin-bottom: 0;
            font-weight: 400;
            font-size: 11px;
        }
    }
    .search_field {
        padding: 0 20px 0 5px;
        display: flex;
        align-items: center;
        // width: 180px;
        i {
            color: $color-dark;
            margin-right: 10px;
            font-size: 18px;
            opacity: 0.3;
        }
        input {
            height: 50px;
            border: none;
        }
    }
    .search_datepicker {
        input {
            height: 38px;
            &:focus {
                border-color: $theme-secondary;
                box-shadow: none;
                border-width: 1px;
            }
        }
    }
    .select_picker {
        // width: 180px;
        color: #000;
    }
    .search_bag_count {
        display: flex;
        align-items: center;
        padding: 0 20px 0 15px;
        width: 165px;
        .bag_icon {
            i {
                color: $color-dark;
                margin-right: 10px;
                font-size: 18px;
                opacity: 0.3;
            }
        }
        .bags_count {
            color: $color-dark;
            font-size: em(18);
        }
        .bags_increment {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: 20px;
            i {
                cursor: pointer;
                color: $color-dark;
                font-size: 16px;
                opacity: 0.3;
            }
        }
    }
    .proceed_search {
        // width: 20%;
        button {
            height: 50px;
            font-size: em(20);
            font-weight: 600;
        }
    }
    @include max-media-medium {
        width: 70%;
        .search_bar_wrapper {
            // width: 70%;
            flex-flow: row wrap;
            margin-bottom: 10px;
        }
        flex-direction: column;
        justify-content: space-between;
        background: transparent;
        // width: 100%;
        // margin: auto;
        margin-left: auto;
        margin-right: auto;
        // margin-top: 10px;
        backdrop-filter: blur(10px);
        background-color: #ff4f5212;
        .custom_form_group {
            label {
                color: #fff;
            }
            width: 100%;
        }
        .select_picker {
            width: 100%;
        }
        .search_field {
            width: 100%;
            background-color: #fff;
            border-radius: 5px;
            margin-bottom: 10px;
            input {
                height: 35px;
                width: 100%;
            }
        }
        .search_datepicker {
            background-color: #fff;
            border-radius: 5px;
            // margin-bottom: 10px;
            width: 100%;

            // .MuiOutlinedInput-input {
            //     height: 0px !important;
            // }
        }
        .search_bag_count {
            padding: 0px 4px;
            background-color: #fff;
            border-radius: 5px;
            margin-bottom: 10px;
            width: 49%;
            height: 35px;
            justify-content: space-between;
            padding: 0px 15px;
            .bag_icon {
                i {
                    font-size: 14px;
                }
            }
            .bags_count {
                font-size: em(14);
            }
            .bags_increment {
                margin-left: 10px;
                i {
                    font-size: 14px;
                    margin-right: 0;
                }
            }
        }
        .proceed_search {
            width: 100%;
            button {
                width: 90px;
                height: 90px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
                margin: auto;
            }
        }
    }

    @include two-media-small {
        width: 100%;
        .custom_form_group {
            width: 100%;
        }
    }

    // @include media-medium {
    //     .search_field {
    //         // width: 200px;

    //         input {
    //             height: 35px;
    //         }
    //     }
    //     .search_datepicker {
    //         // width: 150px;
    //         .MuiOutlinedInput-input {
    //             height: 0px !important;
    //         }
    //     }
    //     .search_bag_count {
    //         width: 100px;
    //         padding: 0px 4px;
    //         .bag_icon {
    //             i {
    //                 font-size: 14px;
    //             }
    //         }
    //         .bags_count {
    //             font-size: em(14);
    //         }
    //         .bags_increment {
    //             margin-left: 10px;
    //             i {
    //                 font-size: 14px;
    //                 margin-right: 0;
    //             }
    //         }
    //     }
    //     .proceed_search {
    //         button {
    //             height: 35px;
    //         }
    //     }
    // }
}
