@import "../common/card";

.featured_prop {
    .d-flex {
        gap: 30px 15px;
    }

    .sld {
        .slick-slide {
            padding: 0 10px;
        }

        .slick-next {
            right: -20px;
            color: #000;
            border-radius: 4px 0 0 4px;
            padding: 10px 5px;
            height: 40px;
            width: 30px;
            transition: 0.3s all ease;

            &:before {
                content: "\f105";
                font-family: "Font Awesome 5 Pro";
                color: #000;
                font-size: 28px;
            }

            &:hover {
                &::before {
                    transition: 0.3s all ease;
                    color: $theme-primary;
                }
            }
        }

        .slick-prev {
            left: -20px;
            border-radius: 0 4px 4px 0;
            padding: 10px 5px;
            z-index: 9;
            color: #000;
            height: 40px;
            width: 30px;
            transition: 0.3s all ease;

            &:before {
                content: "\f104";
                font-family: "Font Awesome 5 Pro";
                color: #000;
                font-size: 28px;
            }

            &:hover {
                &::before {
                    color: $theme-primary;
                    transition: 0.3s all ease;
                }
            }
        }
    }
}

@media(max-width: 767px) {
    .featured_prop {

        .sld {
            .slick-slide {
                padding: 0 15px;
            }

            .slick-next {
                right: -10px;
            }

            .slick-prev {
                left: -10px;
            }
        }
    }
}

@media(max-width: 550px) {
    .featured_prop {
        .d-flex {
            justify-content: center;
        }
    }
}