.developer_detail {
    padding: 40px 0;

    p {
        font-size: 14px;
        margin-bottom: 15px;
        text-align: justify;
        color: $light-text;
    }

    .developer_img_bog {
        border-radius: 10px;
        padding: 16px;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        min-height: 180px;

        >img {
            object-fit: scale-down;
        }
    }
}