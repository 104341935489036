footer {
    background-color: #f9f9f9;
    border-top: 2px solid $light-grey;

    img {
        max-width: 100px;
    }

    h3 {
        font-size: 20px;
        margin-bottom: 10px;
        font-weight: 600;
    }

    p {
        font-size: em(18);
        color: $light-text;
    }

    a {
        color: $light-text;
        text-decoration: none;
        display: block;
        margin-top: 5px;
        font-size: em(16);
        transition: 0.3s all ease;
        width: fit-content;

        &:hover {
            color: $theme-primary;
            transition: 0.3s all ease;
        }
    }

    .soc_bx {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-top: 15px;

        a {
            font-size: 22px;

            &:nth-of-type(1) {
                color: #1877f2;
            }

            &:nth-of-type(2) {
                color: transparent;
                background: radial-gradient(
                    circle at 30% 107%,
                    #fdf497 0%,
                    #fdf497 5%,
                    #fd5949 45%,
                    #d6249f 60%,
                    #285aeb 90%
                );
                background: -webkit-radial-gradient(
                    circle at 30% 107%,
                    #fdf497 0%,
                    #fdf497 5%,
                    #fd5949 45%,
                    #d6249f 60%,
                    #285aeb 90%
                );
                background-clip: text;
                -webkit-background-clip: text;
            }

            &:nth-of-type(3) {
                color: #00acee;
            }

            &:nth-of-type(4) {
                color: #c4302b;
            }
        }
    }

    .btm {
        border-top: 1px solid $light-grey;
        margin: 25px 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        .lft {
            display: flex;
            flex-wrap: wrap;
            gap: 15px 25px;
        }
    }
}

@media (max-width: 550px) {
    footer {
        h3 {
            font-size: 18px;
        }
    }
}
